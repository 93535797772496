import { IGetStorages } from './types'

export const getStoragesVars: IGetStorages = {
  enclosure: {
    in: ['WarehouseRDC'],
  },
  temperatureConditions: {
    in: ['Ambient', 'RoomTemperature', 'Chilled', 'Frozen'],
  },
  secondaryDataReferenceSourceType: {
    in: [
      'PrimaryDataFromIndustrySenseChecked',
      'PrimaryDataMeasurementsTakenByMondra',
      'PeerReviewedPublishedLiterature',
      'PublishedBook',
      'IndustryPublishedReport',
      'Ecoinvent',
      'AgriFootPrint',
      'GovernmentFundedOrganizationReport',
      'NonGovernmentalFundedOrganizationReport',
      'Patent',
      'CompanyWebsite',
      'BlogWebsiteForum',
      'OpenSourceWebsite',
      'UndocumentedEstimate',
    ],
  },
  foodCategory: {
    in: ['LowDensity', 'MediumDensity', 'HighDensity'],
  },
  state: {
    in: ['Unpublished', 'Published'],
  },
}
