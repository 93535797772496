import { combineReducers, configureStore } from '@reduxjs/toolkit'
import packagingSlice from './packagingSlice'
import transportSlice from './transportSlice'
import resourcesSlice from './resourcesSlice'
import auditSlice from './auditSlice'
import salesSlice from './salesSlice'
import storageSlice from './storageSlice'
import companiesSlice from './companiesSlice'
import processSlice from './processSlice'
import processedIngredientSlice from './processedIngredient'
import specialIngredientSlice from './specialIngredientSlice'
import produceSlice from './produceSlice'
import productSlice from './productSlice'
import factorSlice from './factorSlice'
import correlationIdSlice from './correlationIdSlice'

const rootReducer = combineReducers({
  packagingSlice: packagingSlice,
  transportSlice: transportSlice,
  resourcesSlice: resourcesSlice,
  storageSlice: storageSlice,
  salesSlice: salesSlice,
  processSlice: processSlice,
  auditSlice: auditSlice,
  companiesSlice: companiesSlice,
  processedIngredientSlice: processedIngredientSlice,
  specialIngredientSlice: specialIngredientSlice,
  produceSlice: produceSlice,
  productSlice: productSlice,
  factorSlice: factorSlice,
  correlationId: correlationIdSlice,
})

const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default setupStore
