import React from 'react'
import { Link } from 'react-router-dom'

export const LinkCellRenderer = props => {
  const { getUrl, data, dataKey } = props
  const url = getUrl(data)

  if (!url) {
    return <>{data[dataKey]}</>
  }

  return (
    <div className="overflow-hidden overflow-ellipsis underline">
      {dataKey === 'clickUpId' || dataKey === 'additionalSupplementaryInformation' ? (
        <a className="" href={url} target="_blank" rel="noopener noreferrer">
          {data[dataKey]}
        </a>
      ) : (
        <Link className="text-brand hover:text-primary-700" to={url}>
          {data[dataKey]}
        </Link>
      )}
    </div>
  )
}
