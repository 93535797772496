import React, { useEffect, useReducer, useState } from 'react'
import { Button, Textarea, ToggleSwitch } from '@mondra/ui-components'
import { useAuth0 } from '@auth0/auth0-react'
import { updateVerificationFields } from './api'
import { ActivityTypes } from '../../const'

const reducer = (state: any, newState: any) => {
  if (newState) {
    return {
      ...state,
      ...newState,
    }
  }
  return state
}

export interface IVerifyEntry {
  activityId: string
  activityType: ActivityTypes
  isVerified: boolean
  verificationComments: string | null
  verifiedByName: string | null
  verifiedOn: string | null
}

const VerifyEntry: React.FC<IVerifyEntry> = ({
  activityId,
  activityType,
  isVerified,
  verificationComments,
  verifiedByName,
  verifiedOn,
}) => {
  const { user } = useAuth0()
  const [showError, setShowError] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  const [state, dispatch] = useReducer(reducer, {
    isVerified,
    verifiedByName,
    verifiedOn,
    verificationComments,
  })

  useEffect(() => {
    dispatch({
      isVerified,
      verificationComments,
      verifiedByName,
      verifiedOn,
    })
  }, [isVerified, verificationComments, verifiedByName, verifiedOn])

  const handleToggle = (checked: boolean) => {
    if (checked) {
      dispatch({
        isVerified: true,
        verifiedByName: user?.name,
        verifiedOn: new Date().toISOString(),
      })
    } else {
      dispatch({
        isVerified: false,
        verifiedByName: '',
        verifiedOn: '',
        verificationComments: '',
      })
    }
  }

  const handleCommentChange = (event: any) => {
    dispatch({ verificationComments: event.target.value })
  }

  const handleSave = async () => {
    if (!activityId || !activityType) {
      return
    }
    try {
      await updateVerificationFields(activityType, activityId, {
        isVerified: state.isVerified,
        verificationComments: state.verificationComments,
      })
      setShowNotification(true)
      setTimeout(() => {
        setShowNotification(false)
      }, 5000)
    } catch (error: any) {
      setShowError(
        typeof error.response.data === 'string'
          ? error.response.data
          : typeof error.response.data.errors === 'string'
          ? error.response.data.errors
          : 'Error while saving'
      )
      setTimeout(() => {
        setShowError('')
      }, 5000)
    }
  }

  return (
    <div className="card mt-4 flex flex-col gap-4 px-1">
      <div className="flex flex-row items-center justify-between">
        <h4 className="text-sm">Audit</h4>
        <div>
          <ToggleSwitch checked={state.isVerified} onChange={handleToggle} />
        </div>
      </div>
      {state.isVerified && (
        <div className="card-content">
          <span>
            Audited by: <b>{state.verifiedByName}</b>
          </span>
          {state.verifiedOn && (
            <span>
              Audited on: <b>{new Date(state.verifiedOn).toLocaleString()}</b>
            </span>
          )}

          <div>
            <span className="mb-2 block text-sm">Verification comments (max 500 chars)</span>
            <Textarea
              className="w-full text-xs"
              name="verificationComments"
              rows={6}
              maxLength={500}
              value={state.verificationComments || ''}
              onChange={handleCommentChange}
            />
          </div>
        </div>
      )}
      <div className="text-right">
        <Button variant="primary" size="md" className="w-32" type="button" onClick={handleSave}>
          Save
        </Button>
      </div>
      {showError !== '' && <div className="text-red-500">{showError}</div>}
      {showNotification && <div className="text-brand">Successfully saved.</div>}
    </div>
  )
}

export default VerifyEntry
