import { IGetSales } from './types'

export const getSalesVars: IGetSales = {
  channel: {
    in: ['Supermarket', 'Restaurant', 'ECommerce'],
  },
  temperatureConditions: {
    in: ['Ambient', 'RoomTemperature', 'Chilled', 'Frozen'],
  },
  secondaryDataReferenceSourceType: {
    in: [
      'PrimaryDataFromIndustrySenseChecked',
      'PrimaryDataMeasurementsTakenByMondra',
      'PeerReviewedPublishedLiterature',
      'PublishedBook',
      'IndustryPublishedReport',
      'Ecoinvent',
      'AgriFootPrint',
      'GovernmentFundedOrganizationReport',
      'NonGovernmentalFundedOrganizationReport',
      'Patent',
      'CompanyWebsite',
      'BlogWebsiteForum',
      'OpenSourceWebsite',
      'UndocumentedEstimate',
    ],
  },
  shelfLife: {
    in: ['ShortShelfLife', 'MediumShelfLife', 'HighShelfLife'],
  },
  state: {
    in: ['Unpublished', 'Published'],
  },
}
