import { AppDispatch } from '../../../redux/store'
import { getSpecialIngredients, getSpecialIngredientsConfiguration } from './api'
import {
  specialRequest,
  specialSuccess,
  specialFailure,
  configuration,
} from '../../../redux/specialIngredientSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchSpecialIngredient =
  (pieceId: string, replace) => async (dispatch: AppDispatch) => {
    try {
      dispatch(specialRequest())
      const { data: pieceData } = await getSpecialIngredients(pieceId)
      dispatch(specialSuccess(pieceData))
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId))
      }
      if (error.response.status == 400) {
        replace()
      }
      console.log(error, 22)
      dispatch(specialFailure(error.message))
    }
  }

export const getConfiguration = (pieceId: string) => async (dispatch: AppDispatch) => {
  try {
    const { data: configurationData } = await getSpecialIngredientsConfiguration(pieceId)
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(specialFailure(error.message))
  }
}
