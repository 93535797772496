import { ChangeEvent, FC, useEffect, useState } from 'react'
import { IOptionProps } from '../../types/componentTypes'
import CreatableSelect from 'react-select/creatable'
import { ConfirmDialog, FormDialog, Icon, Input } from '@mondra/ui-components'

interface CreatableSelectProps {
  name: string
  description?: string
  defaultValue?: string | number
  options?: IOptionProps[]
  formikName?: string
  className?: string
  create?: boolean
  onCreateOption: (data) => void
  onChange?: (id) => void
  disabled?: boolean
  formik?: any
  showAsterisk?: boolean
  unpublished?: number | null
  edit?: boolean
  code?: string | null
  codeDisabled?: boolean
  onEditClick?: (name: string | number, code: string | null) => void
  onPublishClick?: () => void
  onUnpublishClick?: () => void
}

const SelectCreatable: FC<CreatableSelectProps> = ({
  className,
  name,
  description,
  formikName,
  onCreateOption,
  onChange = () => {},
  formik,
  showAsterisk = false,
  options = [],
  disabled,
  codeDisabled = true,
  unpublished,
  edit,
  code = null,
  onEditClick = () => {},
  onPublishClick = () => {},
  onUnpublishClick = () => {},
}) => {
  const [selectOptions, setSelectOptions] = useState<IOptionProps[]>(options)
  const [currentValue, setCurrentValue] = useState<undefined | IOptionProps>(undefined)
  const [showForm, setShowForm] = useState(false)
  const [showPublish, setShowPublish] = useState(false)
  const [showUnpublish, setShowUnpublish] = useState(false)
  const [formValue, setFormValue] = useState<string | number>('')
  const [codeValue, setCodeValue] = useState<string | null>(null)

  useEffect(() => {
    setSelectOptions(options)
    setCodeValue(code)
    if (currentValue) {
      setCurrentValue({
        label: options.filter(el => el.value === currentValue?.value)[0]?.label || '',
        value: options.filter(el => el.value === currentValue?.value)[0]?.value || '',
      })
    }
  }, [options])

  return (
    <div className={`inputItem ${className && className}`}>
      <div className="inputItem-header">
        <div>
          <div className="flex items-center gap-x-2">
            <h3>
              {name} {showAsterisk && <span className="text-red-500">*</span>}
            </h3>
            {edit && (
              <Icon
                onClick={() => setShowForm(true)}
                type="edit"
                className="w-5 cursor-pointer text-cyan-400"
              />
            )}
            {unpublished === 0 && (
              <Icon
                onClick={() =>
                  sessionStorage.getItem('permission') == 'true' && setShowPublish(true)
                }
                type={'warning'}
                className="w-5 cursor-pointer text-rose-700"
              />
            )}
            {unpublished === 1 && (
              <Icon
                onClick={() =>
                  sessionStorage.getItem('permission') == 'true' && setShowUnpublish(true)
                }
                type={'checkmarkOutline'}
                className="w-5 cursor-pointer text-emerald-700"
              />
            )}
          </div>
          <p className={'mb-1 decoration-gray-600'}>{description}</p>
        </div>
      </div>
      <CreatableSelect
        isDisabled={disabled}
        name={formikName}
        onCreateOption={(inputValue: string) => {
          onCreateOption(inputValue)
          setFormValue(inputValue)
          setCurrentValue({
            label: inputValue,
            value: -1,
          })
        }}
        onChange={(newValue: any, action: any) => {
          onChange(newValue.value)
          setFormValue(newValue.label)
          formik.setFieldValue(formikName, newValue.value)
          setCurrentValue({ label: newValue.label, value: newValue.value })
        }}
        options={selectOptions}
        value={currentValue}
      />
      <FormDialog
        title={`Edit ${name}`}
        formId="input"
        onClose={() => setTimeout(() => setShowForm(false), 500)}
        primaryBtnText="Update"
        onPrimaryButtonClick={() => {
          onEditClick(formValue, codeValue)
          setTimeout(() => setShowForm(false), 500)
        }}
        open={showForm}
      >
        <h4 className="text-base">Please check this {name} before publishing it:</h4>
        <Input
          className="mt-5"
          value={formValue}
          onChange={e => setFormValue((e.target as any).value)}
        />
        <h5 className="mt-5 text-base">Please, add the external code for this {name}:</h5>
        <Input
          className="mt-2"
          disabled={codeDisabled}
          value={codeValue || ''}
          onChange={e => setCodeValue((e.target as any).value)}
        />
      </FormDialog>
      <ConfirmDialog
        title={`Publish ${name}`}
        primaryBtnText="Publish"
        variant="primary"
        onClose={() => setTimeout(() => setShowPublish(false), 500)}
        open={showPublish}
        onPrimaryBtnClick={() => {
          onPublishClick()
          setTimeout(() => setShowPublish(false), 500)
        }}
      >
        <div className="text-lg">
          Please check all information about this {name} and publish it.
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        title={`Unpublish ${name}`}
        primaryBtnText="Unpublish"
        variant="danger"
        onClose={() => setTimeout(() => setShowUnpublish(false), 500)}
        open={showUnpublish}
        onPrimaryBtnClick={() => {
          onUnpublishClick()
          setTimeout(() => setShowUnpublish(false), 500)
        }}
      >
        <div className="text-lg">
          Are you sure you want to unpublish this {name}? Unpublishing {name} will lead to
          unpublishing all its children too.
        </div>
      </ConfirmDialog>
    </div>
  )
}

export default SelectCreatable
