import { gql } from '@apollo/client'

export const GET_ALL_PROCESSED_ING = gql`
  query AllPieces(
    $str: String
    $state: StateEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [TaxonomyProcessedIngFunctionalUnitsGraphQLSortInput!]
  ) {
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }
    taxonomyProcessedFunctionalUnit: __type(name: "TaxonomyProcessedIngFunctionalUnitsGraphQL") {
      fields {
        name
        description
      }
    }

    taxonomyProcessedFunctionalUnits(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { state: $state }
          { code: { contains: $str } }
          { activity: { contains: $str } }
          { category: { contains: $str } }
          { subcategory: { contains: $str } }
          { functionalUnit: { contains: $str } }
          { functionalUnitAliases: { contains: $str } }
          { faoTrade: { contains: $str } }
          { faoProduction: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { researcherName: { contains: $str } }
        ]
      }
      order: $order
    ) {
      totalCount
      edges {
        node {
          id
          code
          state
          activity
          category
          subcategory
          functionalUnit
          functionalUnitAliases
          transportCondition
          isTransportAirFreight
          upstreamIngredientType
          upstreamIngredient
          faoTrade
          faoTradeSource
          faoProduction
          faoProductionSource
          isVerified
          verifiedOn
          lastPublishedDate
          lastUpdatedName
          updated
          researcherName
          created
        }

        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
