import { gql } from '@apollo/client'

export const GET_ALL_TRANSPORT = gql`
  query AllPieces(
    $str: String
    $resource: NullableOfTransportResourceEnumOperationFilterInput
    $temperatureConditions: NullableOfTemperatureConditionsEnumOperationFilterInput
    $medium: NullableOfMediumEnumOperationFilterInput
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $state: StateEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [TransportActivitiesGraphQlSortInput!]
  ) {
    resource: __type(name: "TransportResourceEnum") {
      name
      enumValues {
        name
        description
      }
    }
    temperatureConditions: __type(name: "TemperatureConditionsEnum") {
      name
      enumValues {
        name
        description
      }
    }
    medium: __type(name: "MediumEnum") {
      name
      enumValues {
        name
        description
      }
    }
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }
    transportActivity: __type(name: "TransportActivitiesGraphQl") {
      fields {
        name
        description
      }
    }

    transportActivities(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { countryName: { contains: $str } }
          { countryISOCode: { contains: $str } }
          { regionIdentifier: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { code: { contains: $str } }
          { resource: $resource }
          { medium: $medium }
          { temperatureConditions: $temperatureConditions }
          { secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType }
          { state: $state }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          state
          resource
          temperatureConditions
          medium
          shortDescription
          countryName
          countryISOCode
          regionIdentifier
          carbon
          waterUsage
          eutrophication
          processName
          processId
          description
          comment
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          referencesSamplingYear
          referenceDataPublicationYear
          referenceSourcingCountry
          referenceCountryISOCode
          referenceRegionIdentifier
          dataQualityComment
          lastPublishedDate
          researcherName
          isVerified
          verifiedOn
          updated
          lastUpdatedName
        }
        cursor
      }
    }
  }
`
