import { IProduct, IConfiguration } from '../modules/product/product-gpc/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
interface IState {
  product: IProduct
  loading: boolean
  error: string
  configuration: IConfiguration
}

const initialState: IState = {
  configuration: {
    segment: '',
    segmentId: 0,
    family: '',
    familyId: 0,
    class: '',
    classId: 0,
    brick: '',
    brickId: 0,
    transportCondition: [],
    storageFoodCategory: [],
    packagingPieces: [],
    regions: [],
    countries: [],
    secondaryDataReferenceSourceType: [],
  },
  product: {
    id: 1,
    userEmail: '',
    lastUpdatedName: '',
    lastPublishedDate: '',
    researcherName: '',
    researcherEmail: '',
    updated: '',
    definitionIncludes: '',
    definitionExcludes: '',
    complexIngredientClassification: false,
    state: 0,
    created: '',
    brickCode: '',
    name: '',
    mainAttributeId: null,
    approvedForRelease: false,
    attributes: [],
    packagingPieces: [],
    transportCondition: -1,
    storageFoodCategory: -1,
    storageDurationInHours: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
    minCarbonAllowed: null,
    maxCarbonAllowed: null,
    secondaryDataReferenceSourceType: null,
    referenceAuthorName: null,
    referenceLink: null,
    storageReferenceLink: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: null,
    referenceSourcingCountry: null,
    dataQualityComment: null,
  },
  loading: true,
  error: '',
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    productRequest(state) {
      state.loading = true
    },
    productSuccess(state, action: PayloadAction<IProduct>) {
      state.loading = false
      state.product = action.payload
    },
    productFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.product.state = action.payload
    },
    setDefaultProduct: () => initialState,
  },
})

export default productSlice.reducer
export const {
  productRequest,
  productSuccess,
  productFailure,
  handlePublishStatus,
  setDefaultProduct,
  configuration,
} = productSlice.actions
