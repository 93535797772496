import React, { FC, useEffect, useState, EffectCallback } from 'react'
import { useAppDispatch } from '../../../hooks/redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Breadcrumbs, Button, Icon, LottieLoader, Notification } from '@mondra/ui-components'
import { useAppSelector } from '../../../hooks/redux'
import { useFormik } from 'formik'
import { fetchProduce, getConfiguration } from './produceActions'
import { getFieldAudit } from '../../../redux/action-creators/auditActions'
import { sortDropdown, transformFormValues } from '../../../shared/formFunctions'
import { formSchema } from './utils'
import {
  updateProduce,
  createProduce,
  getProcessedDropdowns,
  getProcessedClass,
  addProduceCategory,
  publishPath,
  unpublishPath,
  updateProducePath,
  getFAOItems,
} from './api'

import { usePrompt } from '../../../hooks/usePrompt'

import { handlePublishStatus, setDefaultIngredient } from '../../../redux/produceSlice'

import InputItem from '../../../components/InputItem'

import './index.scss'
import SelectCreatable from '../../../components/CreatableSelect'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'
import ActivityRightPanel from '../../../components/ActivityRightPanel'
import { ActivityTypes } from '../../../const'

interface Dropdown {
  id: number
  code: string
  name: string
  state: number
}

interface FAODropdown extends Dropdown {
  faoProduceTradeType: number
  faoProduceProductionType: number
}

const ProduceIngredient: FC = ({}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const { loading, ingredient, configuration } = useAppSelector(state => state.produceSlice)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [showError, setShowError] = useState<string>('')
  const [errors, setErrors] = useState<[any, string[]][]>()
  const [showPublished, setShowPublished] = useState<boolean>(false)
  const [classList, setClassList] = useState<[] | Dropdown[]>([])
  const [category, setCategory] = useState<[] | Dropdown[]>([])
  const [subcategory, setSubcategory] = useState<[] | FAODropdown[] | null>(null)

  let ingredientId = searchParams.get('itemId') || ''
  const isNew = ingredientId === 'new'
  const loaFaoTradeOptions = (inputValue: string, callback: (options: Dropdown[]) => void) => {
    if (formik.values.subcategoryId && inputValue.length > 1) {
      var x = isNew
      var faoType = isNew
        ? subcategory?.find(el => el.id === formik.values.subcategoryId)?.faoProduceTradeType
        : configuration.path.subcategoryFAOProduceTradeType
      getFAOItems(faoType, inputValue).then(res => {
        let data = res.data.map(el => ({
          label: el.name,
          value: el.id,
        }))
        callback(data)
      })
    }
  }

  const loadFaoProductionOptions = (
    inputValue: string,
    callback: (options: Dropdown[]) => void
  ) => {
    if (formik.values.subcategoryId && inputValue.length > 1) {
      var x = isNew
      var faoType = isNew
        ? subcategory?.find(el => el.id === formik.values.subcategoryId)?.faoProduceProductionType
        : configuration.path.subcategoryFAOProduceProductionType
      getFAOItems(faoType, inputValue).then(res => {
        let data = res.data.map(el => ({
          label: el.name,
          value: el.id,
        }))
        callback(data)
      })
    }
  }

  useEffect((): ReturnType<EffectCallback> => {
    if (ingredientId == '') {
      navigate('/not-found')
    }
    if (ingredientId === 'new') {
      dispatch(getConfiguration('0'))
      getProcessedClass().then(res => setClassList(res.data))
    }
    if (ingredientId != 'new') {
      dispatch(getConfiguration(ingredientId))
      dispatch(fetchProduce(ingredientId, () => navigate('/produce')))
    }
    return () => {
      dispatch(setDefaultIngredient())
    }
  }, [])

  useEffect(() => {
    formik.setValues({ ...ingredient })
  }, [ingredient])

  const formik = useFormik({
    initialValues: {
      ...ingredient,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (ingredient.state === 0) {
        publishPath('functionalunit', ingredientId)
          .then(() => {
            dispatch(handlePublishStatus(1))
            setShowPublished(true)
            setTimeout(() => {
              setShowPublished(false)
            }, 4000)
          })
          .catch(error => setShowError(error.response.data.title))
      } else {
        unpublishPath('functionalunit', ingredientId)
          .then(() => {
            dispatch(handlePublishStatus(0))
            setShowPublished(true)
            setTimeout(() => {
              setShowPublished(false)
            }, 4000)
          })
          .catch(error => setShowError(error.response.data.title))
      }
    },
  })

  const saveDraft = async e => {
    e.preventDefault()
    const { values } = formik

    const tValues = transformFormValues(values)

    if (ingredientId === 'new') {
      try {
        await createProduce(tValues).then(() => {
          navigate('/produce')
        })
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId))
        } else {
          setShowError(
            typeof error.response.data === 'string'
              ? error.response.data
              : typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          )
          setTimeout(() => {
            setShowError('')
          }, 5000)
          setErrors(
            typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          )
        }
      }
    } else {
      try {
        await updateProduce(ingredientId, tValues)
          .then(() => {
            dispatch(getConfiguration(ingredientId))
            dispatch(fetchProduce(ingredientId, () => navigate('/produce')))
          })
          .then(() => {
            setShowNotification(true)
            setTimeout(() => {
              setShowNotification(false)
            }, 5000)
          })
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId))
        } else {
          setShowError(
            typeof error.response.data === 'string'
              ? error.response.data
              : typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          )
          setTimeout(() => {
            setShowError('')
          }, 5000)
          setErrors(
            typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          )
        }
      }
    }
  }

  usePrompt(
    'There are unsaved changes, do you want to leave?',
    !isNew ? JSON.stringify(ingredient) != JSON.stringify(formik.values) : false
  )

  return (isNew && classList.length != 0) || loading !== true || isNew ? (
    <>
      <div className="piece content">
        {showError !== '' && (
          <div className={'piece-notification'}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={'piece-notification'}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={'piece-notification'}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mb-3 mt-3">
          <Breadcrumbs
            routes={[
              {
                path: '/produce',
                title: 'Produce Table',
              },
              {
                path: `/produce/ingredient?itemId=${ingredientId}`,
                title: 'Produce Ingredient',
              },
            ]}
          />
        </div>
        {ingredientId !== 'new' && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  ingredient.state === 0 ? 'bg-red-500' : 'bg-emerald-500'
                }`}
              >
                {ingredient.state === 0 ? 'Not Published' : 'Published'}
              </div>
              {ingredient?.lastPublishedDate ? (
                <span className={'inputItem-header'}>
                  {`Last published date: ${new Date(ingredient.lastPublishedDate).toLocaleString()}
                  `}
                </span>
              ) : (
                ''
              )}
            </div>
          </>
        )}
        <div>
          <h5 className="mb-5 mt-5">Indicators information</h5>
          <ul>
            {/* <li className="flex mb-5">
              <Icon type="edit" className="text-cyan-400 mr-3" /> - press this
              button if you want to edit field information
            </li> */}
            <li className="mb-5 flex">
              <Icon type="warning" className="mr-3 text-rose-700" /> - this indicator shows you that
              field value is not published. Press it for publishing field value (only for Managers)
            </li>
            <li className="mb-5 flex">
              <Icon type="checkmarkOutline" className="mr-3 text-emerald-700" /> - this indicator
              shows you that field value is published. Press it for unpublishing field value (only
              for Managers)
            </li>
            {ingredientId !== 'new' && (
              <li className="flex">
                <Icon type="informationSquare" className="mr-3 text-green-200" /> - press this
                button if you want to show audit information of the field
              </li>
            )}
          </ul>
        </div>
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={'mt-5'}>Produce</h5>
          {isNew ? (
            <div className="piece-content-inputs">
              <InputItem
                name="Class"
                description=""
                type="select"
                defaultValue={formik.values.classId}
                showAsterisk
                onChange={e => {
                  getProcessedDropdowns(`category?classId=${(e.target as any).value}`).then(res =>
                    setCategory(res.data)
                  )
                  formik.setFieldValue('classId', +(e.target as any).value)
                }}
                options={classList.map(el => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
              <SelectCreatable
                name="Category"
                create={true}
                formik={formik}
                showAsterisk={true}
                disabled={category.length === 0}
                formikName="categoryId"
                onCreateOption={newValue =>
                  addProduceCategory('category', {
                    name: newValue,
                    classId: 1,
                  }).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => {
                      setCategory(res.data)
                      formik.setFieldValue('categoryId', res.data[res.data.length - 1].id)
                      getProcessedDropdowns(
                        `subcategory?categoryId=${res.data[res.data.length - 1].id}`
                      ).then(res => setSubcategory(res.data))
                    })
                  )
                }
                onChange={id =>
                  getProcessedDropdowns(`subcategory?categoryId=${id}`).then(res =>
                    setSubcategory(res.data)
                  )
                }
                options={category.map(el => ({
                  label: el.name,
                  value: el.id,
                }))}
                unpublished={category.filter(el => el.id === formik.values.categoryId)[0]?.state}
                code={category.filter(el => el.id === formik.values.categoryId)[0]?.code}
                onPublishClick={() => {
                  publishPath('category', formik.values.categoryId).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => setCategory(res.data))
                  )
                }}
                onUnpublishClick={() => {
                  unpublishPath('category', formik.values.categoryId).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => setCategory(res.data))
                  )
                }}
                onEditClick={(name, code) => {
                  updateProducePath('category', {
                    name,
                    code,
                    classId: 1,
                    id: formik.values.categoryId,
                  }).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => setCategory(res.data))
                  )
                }}
              />
              <SelectCreatable
                name="Subcategory"
                create={true}
                showAsterisk
                formik={formik}
                disabled={subcategory === null}
                formikName="subcategoryId"
                onCreateOption={newValue =>
                  addProduceCategory('subcategory', {
                    name: newValue,
                    categoryId: formik.values.categoryId,
                  }).then(() =>
                    getProcessedDropdowns(
                      `subcategory?categoryId=${formik.values.categoryId}`
                    ).then(res => {
                      setSubcategory(res.data)
                      formik.setFieldValue('subcategoryId', res.data[res.data.length - 1].id)
                    })
                  )
                }
                onChange={id => {
                  formik.setFieldValue('faoTradeId', undefined)
                  formik.setFieldValue('faoProductionId', undefined)
                  formik.setFieldValue('faoTradeSource', undefined)
                  formik.setFieldValue('faoProductionSource', undefined)
                }}
                options={
                  (subcategory &&
                    subcategory.map(el => ({
                      label: el.name,
                      value: el.id,
                    }))) ||
                  []
                }
                unpublished={
                  subcategory &&
                  subcategory.filter(el => el.id === formik.values.subcategoryId)[0]?.state
                }
                code={
                  subcategory &&
                  subcategory.filter(el => el.id === formik.values.subcategoryId)[0]?.code
                }
                onPublishClick={() => {
                  publishPath('subcategory', formik.values.subcategoryId)
                    .then(() =>
                      getProcessedDropdowns(
                        `subcategory?categoryId=${formik.values.categoryId}`
                      ).then(res => setSubcategory(res.data))
                    )
                    .catch(() => {
                      setShowError('Please publish category first!')
                      setTimeout(() => {
                        setShowError('')
                      }, 4000)
                    })
                }}
                onUnpublishClick={() => {
                  unpublishPath('subcategory', formik.values.subcategoryId).then(() =>
                    getProcessedDropdowns(
                      `subcategory?categoryId=${formik.values.categoryId}`
                    ).then(res => setSubcategory(res.data))
                  )
                }}
                onEditClick={(name, code) => {
                  updateProducePath('subcategory', {
                    name,
                    code,
                    categoryId: formik.values.categoryId,
                    id: formik.values.subcategoryId,
                  }).then(() =>
                    getProcessedDropdowns(
                      `subcategory?categoryId=${formik.values.categoryId}`
                    ).then(res => setSubcategory(res.data))
                  )
                }}
              />
              <InputItem
                name="Produce Name"
                description=""
                type="input"
                showAsterisk={true}
                disabled={formik.values.subcategoryId === 0}
                formik={formik}
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                formikName="name"
              />
            </div>
          ) : (
            <div className="piece-content-inputs">
              <InputItem
                name="Class"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                onChange={formik.handleChange}
                disabled={true}
                auditHistory={true}
                defaultValue={configuration.path.class}
                formikName="width"
                onGetAuditClick={() =>
                  dispatch(getFieldAudit('Taxonomy/Produce', 'class', configuration?.path?.classId))
                }
              />
              <InputItem
                name="Category"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                onChange={formik.handleChange}
                disabled={true}
                defaultValue={configuration.path.category}
                formikName="width"
                unpublished={configuration.path.categoryState}
                edit={false}
                auditHistory={true}
                code={configuration.path.categoryCode}
                onPublishClick={() => {
                  publishPath('category', configuration.path.categoryId).then(() =>
                    dispatch(getConfiguration(ingredientId))
                  )
                }}
                onUnpublishClick={() => {
                  unpublishPath('category', configuration.path.categoryId).then(() =>
                    dispatch(getConfiguration(ingredientId))
                  )
                }}
                onEditClick={(name, code) => {
                  updateProducePath('category', {
                    name,
                    code,
                    classId: configuration.path.classId,
                    id: configuration.path.categoryId,
                  }).then(() => dispatch(getConfiguration(ingredientId)))
                }}
                onGetAuditClick={() =>
                  dispatch(
                    getFieldAudit('Taxonomy/Produce', 'category', configuration.path?.categoryId)
                  )
                }
              />
              <InputItem
                name="Subcategory"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                onChange={formik.handleChange}
                disabled={true}
                defaultValue={configuration.path.subcategory}
                formikName="width"
                unpublished={configuration.path.subcategoryState}
                edit={false}
                auditHistory={true}
                code={configuration.path.subcategoryCode}
                onPublishClick={() => {
                  publishPath('subcategory', configuration.path.subcategoryId)
                    .then(() => dispatch(getConfiguration(ingredientId)))
                    .catch(() => {
                      setShowError('Please publish category first!')
                      setTimeout(() => {
                        setShowError('')
                      }, 4000)
                    })
                }}
                onUnpublishClick={() => {
                  unpublishPath('subcategory', configuration.path.subcategoryId).then(() =>
                    dispatch(getConfiguration(ingredientId))
                  )
                }}
                onEditClick={(name, code) => {
                  updateProducePath('subcategory', {
                    name,
                    code,
                    categoryId: configuration.path.categoryId,
                    id: configuration.path.subcategoryId,
                  }).then(() => dispatch(getConfiguration(ingredientId)))
                }}
                onGetAuditClick={() =>
                  dispatch(
                    getFieldAudit(
                      'Taxonomy/Produce',
                      'subcategory',
                      configuration.path?.subcategoryId
                    )
                  )
                }
              />
              <InputItem
                name="Produce Name"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                formikName="name"
              />
            </div>
          )}
          <h5 className={'mt-5'}>Produce info</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="External code"
              description=""
              type="input"
              disabled
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ''}
              formikName="code"
            />
            <InputItem
              name="Produce Name Aliases"
              description=""
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.functionalUnitProxy || ''}
              formikName="functionalUnitProxy"
            />
          </div>
          <h5 className={'mt-5'}>FAO</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="FAO Trade Item"
              description=""
              type="autocompleteLockup"
              disabled={formik.values.subcategoryId === 0}
              loadOptions={loaFaoTradeOptions}
              formik={formik}
              onChange={formik.handleChange}
              formikName="faoTradeId"
              defaultValue={
                configuration?.path?.faoTradeId
                  ? {
                      label: configuration.path.faoTradeName,
                      value: configuration.path.faoTradeId,
                    }
                  : undefined
              }
            />

            <InputItem
              name="FAO Trade Source"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.faoTradeSource}
              onChange={formik.handleChange}
              formikName="faoTradeSource"
              showAsterisk={true}
              options={sortDropdown(
                configuration.faoSource,
                formik.values.faoTradeSource || -1,
                'description'
              )}
            />
            <InputItem
              name="FAO Production Item"
              description=""
              type="autocompleteLockup"
              disabled={formik.values.subcategoryId === 0}
              loadOptions={loadFaoProductionOptions}
              formik={formik}
              onChange={formik.handleChange}
              formikName="faoProductionId"
              defaultValue={
                configuration?.path?.faoProductionId
                  ? {
                      label: configuration.path.faoProductionName,
                      value: configuration.path.faoProductionId,
                    }
                  : undefined
              }
            />

            <InputItem
              name="FAO Production Source"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.faoProductionSource}
              onChange={formik.handleChange}
              formikName="faoProductionSource"
              showAsterisk={true}
              options={sortDropdown(
                configuration.faoSource,
                ingredient.faoProductionSource || -1,
                'description'
              )}
            />
          </div>
          <h5 className={'mt-5'}>Transport</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="Transport condition"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.transportCondition}
              onChange={formik.handleChange}
              formikName="transportCondition"
              options={sortDropdown(
                configuration.transportCondition,
                formik.values.transportCondition || -1,
                'description'
              )}
            />
          </div>

          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map(err => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}

          <div className="piece-actions">
            {sessionStorage.getItem('permission') == 'true' && !isNew && (
              <>
                <Button
                  variant="tertiary"
                  type="submit"
                  className="w-32"
                  size="md"
                  disabled={
                    JSON.stringify(ingredient) != JSON.stringify(formik.values) ||
                    configuration.path.subcategoryState === 0 ||
                    formik.values.name === ''
                  }
                >
                  {ingredient.state === 0 ? 'Publish' : 'Unpublish'}
                </Button>
              </>
            )}
            <Button
              variant="primary"
              size="md"
              className="w-32"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
            {JSON.stringify(ingredient) != JSON.stringify(formik.values) && (
              <b className="absolute bottom-0 text-sm text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={ingredientId}
        activityType={ActivityTypes.ProduceIngredient}
        auditCreatedBy={ingredient.researcherName || ''}
        auditLastUpdatedBy={ingredient.lastUpdatedName}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
        hideCompanyselect
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: '10.25rem', width: '10.25rem' }}
      />
    </div>
  )
}

export default ProduceIngredient
