import axios from 'axios'

/**
 * @desc Wrapper for HTTP request.
 * Pass method
 * @param method {string} - HTTP method (GET, POST, PUT, PATCH etc.)
 * @return {function} - function for making the HTTP request
 */
const httpMethod =
  method =>
  async (url, payload = {}, headers = {}, cancelToken = null) => {
    const { params = {}, data = {} } = payload

    headers = {
      ...headers,
      'x-api-companies': sessionStorage.getItem('companies'),
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'X-MONDRA-APP': 'DataPlatform',
    }

    return axios({
      method,
      url,
      params,
      data,
      headers,
      cancelToken,
    }).catch(error => {
      if (error.response.status === 500) {
        const newError = {
          ...error,
          CorrelationId: error.response.data.CorrelationId,
          showCorrelationId: true,
        }
        throw newError
      } else {
        throw error
      }
    })
  }

/**
 * @desc Object with methods for making the HTTP requests.
 * @const
 * @type {object}
 */
const http = {
  get: httpMethod('get'),
  post: httpMethod('post'),
  put: httpMethod('put'),
  patch: httpMethod('patch'),
  delete: httpMethod('delete'),
}

export default http
