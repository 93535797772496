import { FC, useState, ChangeEvent, useEffect } from 'react'
import { Icon, PriorityIcon } from '@mondra/ui-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from '../../hooks/redux'
import { referenceSchema } from './utils'
import './index.scss'

interface NoteItemProps {
  reference?: null | string
  referenceNote?: string | null
  userId?: string
  note?: string | null
  userEmail?: string
  userFullName: string
  created: string
}

const NoteItem: FC<NoteItemProps> = ({ note, userEmail, userFullName, reference, created }) => {
  return (
    <div className="notes-item">
      <div className="notes-item-header flex justify-between">
        <div className="flex gap-3">
          <div className="notes-item-header-avatar">{userFullName && userFullName.charAt(0)}</div>
          <div>
            <h5>{userFullName}</h5>
            <span>{new Date(created).toLocaleString()}</span>
          </div>
        </div>
        {/* <PriorityIcon priority="None" /> */}
      </div>
      {reference ? (
        <a target="_blank" rel="noopener noreferrer" href={reference}>
          <p className={'hover:text-sky-600'}>{note}</p>
        </a>
      ) : (
        <p>{note}</p>
      )}
      <hr />
    </div>
  )
}

interface NotesProps {
  notes: NoteItemProps[]
  notesName: string
  addNote?: (variables: any) => any
  addLocal?: (variables: any) => any
}

const Notes: FC<NotesProps> = ({ notes, notesName, addNote, addLocal }) => {
  const { user } = useAuth0()
  const dispatch = useAppDispatch()

  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [select, setSelect] = useState<boolean>(false)
  const [newNote, setNewNote] = useState<string>('')
  const [newReference, setNewReference] = useState<string>('')
  const [isError, setIsError] = useState<string>('')

  useEffect(() => {
    const validate = async () => {
      try {
        await referenceSchema.validate({
          newReference,
        })
        setIsError('')
      } catch (err: any) {
        setIsError(err.errors[0])
      }
    }
    validate()
  }, [newReference])

  const notesList = notes.filter(el => el.note != null)
  const referensList = notes.filter(el => el.note === null)

  const commentList = (select ? referensList : notesList).map(el => (
    <NoteItem
      key={el.created}
      note={el.note || el.referenceNote}
      reference={el.reference}
      userFullName={el.userFullName}
      created={el.created}
    />
  ))

  const key = notesName.split('.').reverse()

  const onReferenceInputHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNewReference(e.target.value)
  }

  return (
    <div className="notes">
      {showNotes && (
        <div className="notes-full">
          <div className="notes-full-header">
            <h5>Notes and References</h5>
            <Icon className={'cursor-pointer'} type="close" onClick={() => setShowNotes(false)} />
          </div>
          <p>Add or edit notes and manage priorities here.</p>
          <div className="notes-full-select">
            <div
              className={`notes-full-select-item ${!select && 'notes-active'}`}
              onClick={() => setSelect(false)}
            >
              <span>Notes</span>
              <div>{notesList.length}</div>
            </div>
            <div
              className={`notes-full-select-item ${select && 'notes-active'}`}
              onClick={() => setSelect(true)}
            >
              <span>References</span>
              <div>{referensList.length}</div>
            </div>
          </div>
          <hr />
          {commentList}
          <div className="notes-full-input">
            <textarea
              value={newNote}
              onChange={e => setNewNote(e.target.value)}
              className="notes-full-textarea focus:outline-none"
              placeholder="Add note"
            />
            {!select && (
              <button
                onClick={e => {
                  e.preventDefault()
                  addNote &&
                    dispatch(
                      addNote({
                        value: {
                          reference: null,
                          referenceNote: null,
                          note: newNote,
                          userId: sessionStorage.getItem('userId'),
                          userEmail: user?.email,
                          userFullName: user?.name,
                          created: new Date().toISOString(),
                        },
                        key: key[0],
                      })
                    )
                  addLocal &&
                    addLocal({
                      value: {
                        reference: null,
                        referenceNote: null,
                        note: newNote,
                        userId: sessionStorage.getItem('userId'),
                        userEmail: user?.email,
                        userFullName: user?.name,
                        created: new Date().toISOString(),
                      },
                      key: key[0],
                    })
                  setNewNote('')
                }}
                disabled={newNote === '' && true}
              >
                Add
              </button>
            )}
          </div>
          {select && (
            <div className="notes-full-input">
              <textarea
                value={newReference}
                onChange={onReferenceInputHandler}
                className="notes-full-textarea focus:outline-none"
                placeholder="Add reference"
              />
              {isError && <span className="error">{isError}</span>}
              <button
                onClick={e => {
                  e.preventDefault()
                  addNote &&
                    dispatch(
                      addNote({
                        value: {
                          reference: newReference?.trim(),
                          referenceNote: newNote,
                          note: null,
                          userId: sessionStorage.getItem('userId'),
                          userEmail: user?.email,
                          userFullName: user?.name,
                          created: new Date().toISOString(),
                        },
                        key: key[0],
                      })
                    )
                  addLocal &&
                    addLocal({
                      value: {
                        reference: newReference?.trim(),
                        referenceNote: newNote,
                        note: null,
                        userId: sessionStorage.getItem('userId'),
                        userEmail: user?.email,
                        userFullName: user?.name,
                        created: new Date().toISOString(),
                      },
                      key: key[0],
                    })
                  setNewNote('')
                  setNewReference('')
                }}
                disabled={((newReference === '' || newNote === '') && true) || Boolean(isError)}
              >
                Add
              </button>
            </div>
          )}
        </div>
      )}
      <div onClick={() => setShowNotes(true)} className="notes-button">
        <Icon type="addComment" />
        {notes.length !== 0 && <span>{notes.length}</span>}
      </div>
    </div>
  )
}

export default Notes
