import { AppDispatch } from '../../../redux/store'
import { getPackagingPiece, getPackagingPieceConfiguration } from './api'
import {
  pieceRequest,
  pieceSuccess,
  pieceFailure,
  configuration,
} from '../../../redux/packagingSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchPiece = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
  try {
    dispatch(pieceRequest())
    const { data: pieceData } = await getPackagingPiece(pieceId)
    dispatch(pieceSuccess(pieceData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    if (error.response.status == 400) {
      replace()
    }
    dispatch(pieceFailure(error.message))
  }
}

export const getConfiguration = () => async (dispatch: AppDispatch) => {
  try {
    const { data: configurationData } = await getPackagingPieceConfiguration()
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(pieceFailure(error.message))
  }
}
