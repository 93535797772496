import { gql } from '@apollo/client'

export const GET_ALL_PIECES = gql`
  query AllPieces(
    $str: String
    $pieceGroup: NullableOfPackagingPieceGroupEnumOperationFilterInput
    $packagingType: NullableOfPackagingTypeEnumOperationFilterInput
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $state: StateEnumOperationFilterInput
    $typicalUses: NullableOfTypicalUsesEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [PackagingPiecesGraphQlSortInput!]
  ) {
    componentNames: __type(name: "ComponentNameEnum") {
      name
      enumValues {
        name
        description
      }
    }
    pieceGroup: __type(name: "PackagingPieceGroupEnum") {
      name
      enumValues {
        name
        description
      }
    }
    packagingType: __type(name: "PackagingTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }
    typicalUses: __type(name: "TypicalUsesEnum") {
      name
      enumValues {
        name
        description
      }
    }
    packagingPiece: __type(name: "PackagingPiecesGraphQl") {
      fields {
        name
        description
      }
    }
    packagingPieces(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { pieceName: { contains: $str } }
          { researcherName: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { code: { contains: $str } }
          { pieceGroup: $pieceGroup }
          { packagingType: $packagingType }
          { secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType }
          { state: $state }
          { typicalUses: $typicalUses }
        ]
      }
      order: $order
    ) {
      totalCount
      edges {
        node {
          id
          code
          state
          sourcedFromRERRoW
          typicalUses
          packagingType
          pieceGroup
          pieceName
          clickUpId
          totalMass
          maximumLoadingCapacity
          length
          width
          height
          numberOfUses
          packagingMaterialLoss
          numberOfComponents
          material
          carbon
          waterUsage
          eutrophication
          comment
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          referencesSamplingYear
          referenceDataPublicationYear
          referenceSourcingCountry
          referenceCountryISOCode
          referenceRegionIdentifier
          dataQualityComment
          lastPublishedDate
          researcherName
          isVerified
          verifiedOn
          updated
          lastUpdatedName
        }

        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
