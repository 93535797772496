import { AppDispatch } from '../../../redux/store'
import { getEmissionFactors, getEmissionFactorsConfiguration } from './api'
import {
  factorRequest,
  factorSuccess,
  factorFailure,
  configuration,
  factorConfigurationRequest,
  factorConfigurationFailure,
} from '../../../redux/factorSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchFactor = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
  try {
    dispatch(factorRequest())
    const { data: salesData } = await getEmissionFactors(pieceId)
    dispatch(factorSuccess(salesData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    if (error.response.status == 400) {
      replace()
    }
    dispatch(factorFailure(error.message))
  }
}

export const getConfiguration = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(factorConfigurationRequest())
    const { data: configurationData } = await getEmissionFactorsConfiguration(id)
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(factorFailure(error.message))
    dispatch(factorConfigurationFailure())
  }
}
