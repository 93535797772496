import React from 'react'
import Audit from '../Audit'
import CompanySelect from '../CompanySelect'
import VerifyEntry, { IVerifyEntry } from '../VerifyEntry'
import { getAudit } from '../../redux/action-creators/auditActions'
import { useAppDispatch } from '../../hooks/redux'
import { ActivityTypes } from '../../const'

interface IActivityRightPanel extends IVerifyEntry {
  activityId: string
  activityType: ActivityTypes
  auditCreatedBy: string
  auditLastUpdatedBy: string | null
  companyId?: string | null
  onChange: (e: any) => void
  hideAuditButton?: boolean
  hideCompanyselect?: boolean
}

const ActivityRightPanel: React.FC<IActivityRightPanel> = ({
  activityId,
  activityType,
  auditCreatedBy,
  auditLastUpdatedBy,
  companyId,
  onChange,
  hideAuditButton,
  hideCompanyselect,
  ...verifyProps
}) => {
  const dispatch = useAppDispatch()

  return (
    <div className="side">
      <div className="audit">
        {activityId !== 'new' && (
          <Audit
            createdBy={auditCreatedBy}
            lastUpdatedName={auditLastUpdatedBy}
            showAuditButton={!hideAuditButton}
            onClick={() =>
              activityType ? dispatch(getAudit(activityType, activityId)) : undefined
            }
          />
        )}
        {!hideCompanyselect && activityId !== 'new' && companyId !== null && (
          <CompanySelect onChange={onChange} companyId={companyId} disabled={true} />
        )}
        {activityId !== 'new' && (
          <VerifyEntry {...verifyProps} activityId={activityId} activityType={activityType} />
        )}
      </div>
    </div>
  )
}

export default ActivityRightPanel
