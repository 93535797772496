import React, { FC } from 'react'

import { getDefaults, multipleObject, sortDropdown } from '../../../shared/formFunctions'

import Select from 'react-select'
import InputItem from '../../../components/InputItem'
import { IConfiguration, IDropdown, IEmissionFactors, ITabBaseProps } from './types'

interface IProductInfo extends ITabBaseProps {
  configuration: IConfiguration
  isNew: boolean
  prevProductType: React.MutableRefObject<number>
  classes: IDropdown[]
  selectedClass: number
  selectedCategory: number
  category: IDropdown[]
  subcategory: IDropdown[]
  functionalUnit: IDropdown[]
  factor: IEmissionFactors
  selectedProductGroup: number
  onProductTypeChange(e: React.ChangeEvent): void
  onCategoryChange(e: React.ChangeEvent): void
  onClassChange(e: React.ChangeEvent): void
  onProductGroupChange(e: React.ChangeEvent): void
}

export const ProductInfo: FC<IProductInfo> = ({
  isCustomEF,
  formik,
  configuration,
  isNew,
  prevProductType,
  classes,
  selectedClass,
  selectedCategory,
  category,
  subcategory,
  functionalUnit,
  factor,
  selectedProductGroup,
  onProductTypeChange,
  onCategoryChange,
  onClassChange,
  onProductGroupChange,
}) => {
  return (
    <div className="piece-content-inputs mb-5">
      <InputItem
        disabled={isCustomEF.current}
        name="Product type"
        description="Please specify the product type of this entry"
        type="select"
        formikName="productType"
        formik={formik}
        showAsterisk
        defaultValue={formik.values.productType}
        onChange={onProductTypeChange}
        options={sortDropdown(
          configuration.productTypeEFs,
          formik.values.productType,
          'description'
        )}
      />
      {isNew || +prevProductType.current !== formik.values.productType ? (
        <>
          <InputItem
            name="Class"
            description=""
            type="select"
            disabled={classes.length === 0}
            defaultValue={selectedClass}
            onChange={onClassChange}
            options={classes.map(el => ({
              label: el.name,
              value: el.id,
            }))}
          />
          <InputItem
            name="Category"
            description=""
            type="select"
            defaultValue={selectedCategory}
            disabled={category.length === 0}
            onChange={onCategoryChange}
            options={category.map(el => ({
              label: el.name,
              value: el.id,
            }))}
          />
          {formik.values.productType != 3 && (
            <InputItem
              name="Product Group"
              description=""
              type="select"
              create={true}
              disabled={subcategory.length === 0}
              defaultValue={selectedProductGroup}
              onChange={onProductGroupChange}
              options={subcategory.map(el => ({
                label: el.name,
                value: el.id,
              }))}
            />
          )}
          <InputItem
            name="Product Name"
            description="Please select a suitable product Name from the Ingredient taxonomy."
            type="select"
            create={true}
            disabled={functionalUnit.length === 0}
            onChange={formik.handleChange}
            defaultValue={formik.values.productId}
            formikName="productId"
            showAsterisk
            options={functionalUnit.map(el => ({
              label: el.name,
              value: el.id,
            }))}
          />
        </>
      ) : formik.values.productType != 3 ? (
        configuration.productTaxonomies.functionalUnits !== null && (
          <>
            <InputItem
              disabled={isCustomEF.current}
              name="Class"
              description=""
              type="select"
              defaultValue={selectedClass}
              onChange={onClassChange}
              options={
                (classes.length !== 0 &&
                  classes.map(el => ({
                    label: el.name,
                    value: el.id,
                  }))) ||
                configuration.productTaxonomies.classes.map(el => ({
                  label: el.name,
                  value: el.id,
                }))
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Category"
              description=""
              type="select"
              defaultValue={selectedCategory}
              onChange={onCategoryChange}
              options={
                category.length !== 0
                  ? category.map(el => ({
                      label: el.name,
                      value: el.id,
                    }))
                  : sortDropdown(
                      configuration.productTaxonomies.categories,
                      selectedCategory,
                      'name'
                    )
              }
            />
            {formik.values.productType != 3 && (
              <InputItem
                disabled={isCustomEF.current}
                name="Product Group"
                description=""
                type="select"
                defaultValue={selectedProductGroup}
                onChange={onProductGroupChange}
                options={
                  subcategory.length !== 0
                    ? subcategory.map(el => ({
                        label: el.name,
                        value: el.id,
                      }))
                    : sortDropdown(
                        configuration.productTaxonomies.subcategories,
                        selectedProductGroup,
                        'name'
                      )
                }
              />
            )}
            <InputItem
              disabled={isCustomEF.current}
              name="Product Name"
              description="Please select a suitable product Name from the Ingredient taxonomy."
              type="select"
              onChange={formik.handleChange}
              formikName="productId"
              defaultValue={formik.values.productId}
              options={
                functionalUnit.length !== 0 || subcategory.length !== 0
                  ? functionalUnit.map(el => ({
                      label: el.name,
                      value: el.id,
                    }))
                  : sortDropdown(
                      configuration.productTaxonomies.functionalUnits,
                      formik.values.productId,
                      'name'
                    )
              }
            />
          </>
        )
      ) : (
        configuration.specialTaxonomies.functionalUnits !== null && (
          <>
            <InputItem
              disabled={isCustomEF.current}
              name="Class"
              description=""
              type="select"
              defaultValue={selectedClass}
              onChange={onClassChange}
              options={
                (classes.length !== 0 &&
                  classes.map(el => ({
                    label: el.name,
                    value: el.id,
                  }))) ||
                configuration.specialTaxonomies.classes.map(el => ({
                  label: el.name,
                  value: el.id,
                }))
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Category"
              description=""
              type="select"
              defaultValue={selectedCategory}
              onChange={onCategoryChange}
              options={
                category.length !== 0
                  ? category.map(el => ({
                      label: el.name,
                      value: el.id,
                    }))
                  : sortDropdown(
                      configuration.specialTaxonomies.categories,
                      selectedCategory,
                      'name'
                    )
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Product Name"
              description="Please select a suitable product Name from the Ingredient taxonomy."
              type="select"
              onChange={formik.handleChange}
              formikName="productId"
              defaultValue={formik.values.productId}
              options={
                functionalUnit.length !== 0
                  ? functionalUnit.map(el => ({
                      label: el.name,
                      value: el.id,
                    }))
                  : sortDropdown(
                      configuration.specialTaxonomies.functionalUnits,
                      formik.values.productId,
                      'name'
                    )
              }
            />
          </>
        )
      )}
      <InputItem
        disabled={isCustomEF.current}
        name="Product Name at Source"
        description="Please provide the original product/produce name from the original source."
        type="input"
        onChange={formik.handleChange}
        formikName="productNameAtSource"
        showAsterisk
        formik={formik}
        defaultValue={formik.values.productNameAtSource}
      />
      <InputItem
        disabled={isCustomEF.current}
        key={formik.values.countryId ? formik.values.countryId + Math.random() : Math.random()}
        name="Country Name"
        description="The country for which this entry was generated."
        type="select"
        onChange={formik.handleChange}
        formikName="countryId"
        create={true}
        defaultValue={formik.values.countryId ? formik.values.countryId : -1}
        formik={formik}
        options={sortDropdown(
          configuration.countries,
          formik.values.countryId ?? -1,
          'countryName'
        )}
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Country ISO Code"
        description="Please select the country in which the data was collected."
        type="select"
        create={true}
        defaultValue={formik.values.countryId ? formik.values.countryId : -1}
        onChange={formik.handleChange}
        formik={formik}
        formikName="countryId"
        options={sortDropdown(configuration.countries, formik.values.countryId ?? -1, 'countryISO')}
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Region/State Name"
        description="Please select the Region/State if known."
        type="input"
        onChange={formik.handleChange}
        formikName="regionStateName"
        formik={formik}
        defaultValue={formik.values.regionStateName}
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Region Identifier"
        description="Please select the region identifier for this entry."
        type="select"
        defaultValue={formik.values.regionIdentifierId}
        onChange={formik.handleChange}
        formikName="regionIdentifierId"
        formik={formik}
        showAsterisk
        options={sortDropdown(configuration.regions, formik.values.regionIdentifierId, 'name')}
      />
      {formik.values.productType === 0 && (
        <div className="inputItem">
          <h3 className="mt-5">
            Production Systems <span className="text-red-500">*</span>
          </h3>
          <p className={'mb-3 decoration-gray-600'}>
            Please select the production system for this record.
          </p>
          <Select
            isDisabled={isCustomEF.current}
            isClearable={false}
            defaultValue={[
              ...getDefaults(factor.productionSystem, configuration.productionSystems),
            ]}
            name="productionSystem"
            onChange={(newValue: any, action: any) => {
              action.action === 'remove-value' &&
                formik.setFieldValue(
                  'productionSystem',
                  formik.values.productionSystem !== null
                    ? (formik.values.productionSystem -= action.removedValue.value)
                    : null
                )
              action.action === 'select-option' &&
                formik.setFieldValue(
                  'productionSystem',
                  (formik.values.productionSystem += action.option.value)
                )
            }}
            isMulti
            className="basic-multi-select"
            options={multipleObject(configuration.productionSystems)}
          />
          {formik.touched.productionSystem && formik.errors.productionSystem ? (
            <span className="formik-error">{formik.errors.productionSystem}</span>
          ) : (
            ''
          )}
        </div>
      )}
      {formik.values.productType === 0 && (
        <div className="inputItem">
          <h3 className="mt-5 whitespace-nowrap">
            Plant Protection Type Group <span className="text-red-500">*</span>
          </h3>
          <p className={'mb-3 decoration-gray-600'}>
            Please select the plant protection system for this record. Uncovered = no use of any
            plant protection, Covered = any plant protection used as cover
          </p>
          <Select
            isDisabled={isCustomEF.current}
            isClearable={false}
            defaultValue={[
              ...getDefaults(
                factor.plantProtectionTypeGroup,
                configuration.plantProtectionTypeGroups
              ),
            ]}
            name="plantProtectionTypeGroup"
            onChange={(newValue: any, action: any) => {
              action.action === 'remove-value' &&
                formik.setFieldValue(
                  'plantProtectionTypeGroup',
                  formik.values.plantProtectionTypeGroup !== null
                    ? (formik.values.plantProtectionTypeGroup -= action.removedValue.value)
                    : null
                )
              action.action === 'select-option' &&
                formik.setFieldValue(
                  'plantProtectionTypeGroup',
                  (formik.values.plantProtectionTypeGroup += action.option.value)
                )
            }}
            isMulti
            className="basic-multi-select"
            options={multipleObject(configuration.plantProtectionTypeGroups)}
          />
          {formik.touched.plantProtectionTypeGroup && formik.errors.plantProtectionTypeGroup ? (
            <span className="formik-error">{formik.errors.plantProtectionTypeGroup}</span>
          ) : (
            ''
          )}
        </div>
      )}
      {(formik.values.plantProtectionTypeGroup == 1 ||
        formik.values.plantProtectionTypeGroup == 3) && (
        <div className="inputItem">
          <h3 className="mt-5">Plant Protection Type</h3>
          <p className={'mb-3 decoration-gray-600'}>
            Please select the plant protection type for this record.
          </p>
          <Select
            isDisabled={isCustomEF.current}
            isClearable={false}
            defaultValue={[
              ...getDefaults(factor.plantProtectionType, configuration.plantProtectionTypes),
            ]}
            name="plantProtectionType"
            onChange={(newValue: any, action: any) => {
              formik.values.plantProtectionType =
                formik.values.plantProtectionType === null ? 0 : formik.values.plantProtectionType
              action.action === 'remove-value' &&
                formik.setFieldValue(
                  'plantProtectionType',
                  (formik.values.plantProtectionType -= action.removedValue.value)
                )
              action.action === 'select-option' &&
                formik.setFieldValue(
                  'plantProtectionType',
                  (formik.values.plantProtectionType += action.option.value)
                )
            }}
            isMulti
            className="basic-multi-select"
            options={multipleObject(configuration.plantProtectionTypes)}
          />
          {formik.touched.plantProtectionType && formik.errors.plantProtectionType ? (
            <span className="formik-error">{formik.errors.plantProtectionType}</span>
          ) : (
            ''
          )}
        </div>
      )}
      <InputItem
        disabled={isCustomEF.current}
        name="Functional Unit"
        description=""
        type="select"
        defaultValue={formik.values.functionalUnit}
        onChange={formik.handleChange}
        formikName="functionalUnit"
        formik={formik}
        showAsterisk
        options={sortDropdown(
          configuration.functionalUnits,
          formik.values.functionalUnit,
          'description'
        )}
      />
      <InputItem
        name="External code"
        description=""
        type="input"
        formik={formik}
        disabled
        onChange={formik.handleChange}
        defaultValue={formik.values.code || ''}
        formikName="code"
      />
    </div>
  )
}

export default ProductInfo
