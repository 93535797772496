import { AppDispatch } from '../../../redux/store'
import { getProduce, getProduceConfiguration } from './api'
import {
  produceRequest,
  produceSuccess,
  produceFailure,
  configuration,
} from '../../../redux/produceSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchProduce = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
  try {
    dispatch(produceRequest())
    const { data: pieceData } = await getProduce(pieceId)
    dispatch(produceSuccess(pieceData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    if (error.response.status == 400) {
      replace()
    }
    dispatch(produceFailure(error.message))
  }
}

export const getConfiguration = (pieceId: string) => async (dispatch: AppDispatch) => {
  try {
    const { data: configurationData } = await getProduceConfiguration(pieceId)
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(produceFailure(error.message))
  }
}
