import React, { FC, useCallback } from 'react'
import { Icon, Tabs } from '@mondra/ui-components'
import classNames from 'classnames'

interface IContentTab {
  hasErrors: boolean
  label: string
  disabled?: boolean
}

interface IContentTabs {
  items: IContentTab[]
  selectedIndex: number
  onChange(index: number): void
}

export const ContentTabs: FC<IContentTabs> = ({ items, selectedIndex, onChange }) => {
  const getTab = useCallback(
    (tab: IContentTab, index: number) => {
      const isTabActive = index === selectedIndex

      return {
        label: () => (
          <div className="flex flex-row items-center gap-x-2 whitespace-nowrap">
            <div>{tab.label}</div>
            {tab.hasErrors && <Icon type="warningAlt" className="w-5" />}
          </div>
        ),
        className: classNames(
          tab.hasErrors && !isTabActive
            ? '!text-red-500 !bg-red-200 hover:!bg-red-300 hover:!text-error'
            : '',
          tab.hasErrors && isTabActive
            ? '!bg-red-600 !border-red-600 hover:!bg-red-700 hover:!border-red-700'
            : ''
        ),
        disabled: tab.disabled,
        panel: <></>,
      }
    },
    [selectedIndex]
  )

  const tabs = items.map((tab, index) => getTab(tab, index))

  return (
    <Tabs
      tabs={tabs}
      selectedIndex={selectedIndex}
      onChange={onChange}
      size="md"
      panelClassName="hidden"
      className="mb-2"
    />
  )
}

export default ContentTabs
