import { IStorageForm } from './types'
import http from '../../../http'
import { API_URL } from '../../../const'

const STORAGE_ACTIVITY_URL: string = `${API_URL}/storageActivity`

export const getStorageActivity = (pieceId: string) =>
  http.get(`${STORAGE_ACTIVITY_URL}/${pieceId}`)

export const updateStorageActivity = (pieceId: string, data: IStorageForm) =>
  http.put(`${STORAGE_ACTIVITY_URL}/${pieceId}`, { data })

export const publishStorageActivity = (pieceId: string) =>
  http.post(`${STORAGE_ACTIVITY_URL}/publish/${pieceId}`)

export const unPublishStorageActivity = (pieceId: string) =>
  http.delete(`${STORAGE_ACTIVITY_URL}/unpublish/${pieceId}`)

export const deleteStorageActivity = (pieceId: string) =>
  http.delete(`${STORAGE_ACTIVITY_URL}/${pieceId}`)

export const createStorageActivity = data => http.post(`${STORAGE_ACTIVITY_URL}`, { data })

export const getStorageActivityConfiguration = () =>
  http.get(`${STORAGE_ACTIVITY_URL}/configuration`)
