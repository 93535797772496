import { IGetEmissions } from './types'

export const getEmissionVars: IGetEmissions = {
  state: {
    in: ['Unpublished', 'Published'],
  },
  productType: {
    in: ['Produce', 'SpecialIngredient'],
  },
  functionalUnit: {
    in: [
      'FarmedProduceIncludingPostHarvestLosses',
      'ProductSoldInUKFinal',
      'ProductSoldToGlobalFinalExcludingSalesStage',
      'ProductSoldToUKFinalExcludingSalesStage',
      'ProductAtProcessorGate',
    ],
  },
  dataOrigin: {
    in: ['PnNOriginalPublication', 'MondraMA', 'ExternalSource', 'Kynetec', 'Agribalyse', 'Hestia'],
  },
  farmDataCalculator: {
    in: ['HestiaCommunityPlatform', 'PnNCalculator', 'Other'],
  },
  pnNExcelCalculator: {
    in: [
      'MASTERCoffeeCacao',
      'MASTERFruitsWines',
      'MASTERGrains',
      'MASTERNutsBeansPulses',
      'MASTEROilsSunflowerRapeseedPalmFruitOilves',
      'MASTERSpices',
      'MASTERSugar',
      'MASTERVegetables',
      'MASTERMushroom',
      'PnNOriginal',
    ],
  },
  secondaryDataReferenceSourceType: {
    in: [
      'PrimaryDataFromClientSiteCheckMeasurementEvidence',
      'PrimaryDataMeasurementsTakenByMondra',
      'PrimaryDataFromClientSenseChecked',
      'PrimaryDataFromIndustrySenseChecked',
      'PeerReviewedPublishedLiterature',
      'PublishedBook',
      'IndustryPublishedReport',
      'Ecoinvent',
      'AgriFootPrint',
      'GovernmentFundedOrganizationReport',
      'NonGovernmentalFundedOrganizationReport',
      'Patent',
      'CompanyWebsite',
      'BlogWebsiteForum',
      'OpenSourceWebsite',
      'UndocumentedEstimate',
      'ScatteredData',
    ],
  },
}
