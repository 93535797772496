import { IProcessingActivity } from './types'
import http from '../../../http'
import { API_URL } from '../../../const'

const PROCESSING_ACTIVITY_URL: string = `${API_URL}/ProcessingActivity`
const PRODUCT_BASE: string = `${API_URL}/Taxonomy/Product`

export const getProduct = (pieceId: string) =>
  http.get(`${PRODUCT_BASE}/brickWithChildren/${pieceId}`)

export const getProcessingActivity = (pieceId: string) =>
  http.get(`${PROCESSING_ACTIVITY_URL}/${pieceId}`)

export const updateProcessingActivity = (pieceId: string, data: IProcessingActivity) =>
  http.put(`${PROCESSING_ACTIVITY_URL}/${pieceId}`, { data })

export const publishProcessingActivity = (pieceId: string) =>
  http.post(`${PROCESSING_ACTIVITY_URL}/publish/${pieceId}`)

export const unPublishProcessingActivity = (pieceId: string) =>
  http.delete(`${PROCESSING_ACTIVITY_URL}/unpublish/${pieceId}`)

export const deleteProcessingActivity = (pieceId: string) =>
  http.delete(`${PROCESSING_ACTIVITY_URL}/${pieceId}`)

export const createProcessingActivity = data => http.post(`${PROCESSING_ACTIVITY_URL}`, { data })

export const getProcessingActivityConfiguration = () =>
  http.get(`${PROCESSING_ACTIVITY_URL}/configuration`)

export const getProcessedClass = () => http.get(`${API_URL}/Taxonomy/ProcessedIngredient/class`)

export const getSpecialIngredientClass = () =>
  http.get(`${API_URL}/Taxonomy/SpecialIngredient/class`)

export const getProcessedDropdowns = (level: string) =>
  http.get(`${API_URL}/Taxonomy/ProcessedIngredient/${level}`)
export const getSpecialIngredientDropdowns = (level: string) =>
  http.get(`${API_URL}/Taxonomy/SpecialIngredient/${level}`)
export const getProduceDropdowns = (level: string) =>
  http.get(`${API_URL}/Taxonomy/Produce/${level}`)
export const getProductDropdowns = (level: string) =>
  http.get(`${API_URL}/Taxonomy/Product/${level}`)
export const getSpecialDropdowns = (level: string) =>
  http.get(`${API_URL}/Taxonomy/SpecialIngredient/${level}`)
