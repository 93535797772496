import { IGetProcessing } from './types'

export const getProcessingVars: IGetProcessing = {
  secondaryDataReferenceSourceType: {
    in: [
      'PrimaryDataFromIndustrySenseChecked',
      'PrimaryDataMeasurementsTakenByMondra',
      'PeerReviewedPublishedLiterature',
      'PublishedBook',
      'IndustryPublishedReport',
      'Ecoinvent',
      'AgriFootPrint',
      'GovernmentFundedOrganizationReport',
      'NonGovernmentalFundedOrganizationReport',
      'Patent',
      'CompanyWebsite',
      'BlogWebsiteForum',
      'OpenSourceWebsite',
      'UndocumentedEstimate',
    ],
  },
  state: {
    in: ['Unpublished', 'Published'],
  },
  functionalUnit: {
    in: [
      'FarmedProduceIncludingPostHarvestLosses',
      'ProductSoldInUKFinal',
      'ProductSoldToGlobalFinalExcludingSalesStage',
      'ProductSoldToUKFinalExcludingSalesStage',
      'ProductAtProcessorGate',
    ],
  },
}
