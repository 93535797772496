import { FC, useState } from 'react'
import { FormDialog, Button } from '@mondra/ui-components'
import InputItem from '../../../../components/InputItem'
import { useFormik } from 'formik'
import { wasteSchema } from '../utils'
import { addLocalPopUpNote } from '../../../../shared/noteFunctions'

interface ComponentFormProps {
  resourceSources: any
  addProcess: (variables?: any, array?: string) => void
}

const WasteForm: FC<ComponentFormProps> = ({ resourceSources, addProcess }) => {
  const [openForm, setOpenForm] = useState(false)

  const initialValues = {
    quantity: '',
    resourceSourceId: -1,
    quantityNotes: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: wasteSchema,
    onSubmit: values => {
      values.resourceSourceId = +values.resourceSourceId
      addProcess(values, 'waste')
      setTimeout(() => setOpenForm(false), 500)
    },
  })

  const onCreateComponent = () => {
    setOpenForm(true)
    formik.setValues(initialValues)
    formik.setTouched({}, false)
  }

  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className="mt-5"
        onClick={onCreateComponent}
      >
        Add waste
      </Button>
      <FormDialog
        title="Add waste"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              name="Waste type"
              description="Please specify what kind of waste was generated during the process. Please provide the waste in the units displayed. Wastewater density can be assumed to be similar to water, for oil waste please consider an average density of 914.5 kg/m3."
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              defaultValue={formik.values.resourceSourceId ? formik.values.resourceSourceId : -1}
              options={resourceSources.map(el => ({
                label: el.combinedName,
                value: el.id,
              }))}
            />
            <InputItem
              name="Waste ID"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              defaultValue={formik.values.resourceSourceId ? formik.values.resourceSourceId : -1}
              options={resourceSources.map(el => ({
                label: el.combinedId,
                value: el.id,
              }))}
            />
            <InputItem
              name="Quantity"
              description="Please provide the quantity of the waste in the displayed unit"
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.quantity}
              formikName="quantity"
              showAsterisk
              addonTitle={
                resourceSources.find(el => el.id === +formik.values.resourceSourceId)?.unit
              }
              addLocal={params =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.quantityNotes.length,
                  ...params,
                })
              }
              notes={formik.values.quantityNotes || []}
            />
          </div>
        </form>
      </FormDialog>
    </>
  )
}

export default WasteForm
