import React, { FC } from 'react'

import InputItem from '../../../components/InputItem'
import { sortDropdown } from '../../../shared/formFunctions'
import { addLocalPopUpNote } from '../../../shared/noteFunctions'
import { IConfiguration, ITabBaseProps } from './types'

interface ILosses extends ITabBaseProps {
  configuration: IConfiguration
}

export const Losses: FC<ILosses> = ({ isCustomEF, formik, configuration }) => {
  return (
    <div className="piece-content-inputs mb-5">
      <InputItem
        disabled={isCustomEF.current}
        name="Post Harvest Losses ID"
        description="Please select the produce Post Harvest Losses ID from FAOSTAT (2017) PnN that was used for this entry."
        type="select"
        onChange={formik.handleChange}
        formikName="postHarvestLossesId"
        showAsterisk
        formik={formik}
        defaultValue={
          formik.values.postHarvestLossesId !== null && formik.values.postHarvestLossesId >= 0
            ? formik.values.postHarvestLossesId
            : -1
        }
        options={sortDropdown(
          configuration.postHarvestLossesIds,
          formik.values.postHarvestLossesId ?? -1,
          'description'
        )}
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Post Harvest Losses"
        description="Please indicate the Post Harvest losses in %."
        type="input"
        onChange={formik.handleChange}
        formikName="postHarvestLosses"
        addonTitle="%"
        formik={formik}
        showAsterisk
        addLocal={params =>
          addLocalPopUpNote({
            formik,
            index: formik.values.postHarvestLossesNotes.length,
            ...params,
          })
        }
        notes={formik.values.postHarvestLossesNotes || []}
        defaultValue={
          formik.values.postHarvestLosses === null ? '' : formik.values.postHarvestLosses.toString()
        }
      />
    </div>
  )
}

export default Losses
