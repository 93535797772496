import { AppDispatch } from '../../../redux/store'
import { getSalesActivity, getSalesActivityConfiguration } from './api'
import { salesRequest, salesSuccess, salesFailure, configuration } from '../../../redux/salesSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchSales = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesRequest())
    const { data: salesData } = await getSalesActivity(pieceId)
    dispatch(salesSuccess(salesData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    if (error.response.status == 400) {
      replace()
    }
    dispatch(salesFailure(error.message))
  }
}

export const getConfiguration = () => async (dispatch: AppDispatch) => {
  try {
    const { data: configurationData } = await getSalesActivityConfiguration()
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(salesFailure(error.message))
  }
}
