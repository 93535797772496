import { FC } from 'react'
import Header from '../Header'
import Menu from '../Menu'

import './index.scss'

interface LayoutProps {
  children: React.ReactNode
  name: string
}

const Layout: FC<LayoutProps> = ({ children, name }) => {
  return (
    <main>
      <Header name={name} />
      <div className="layout">
        <Menu />
        {children}
      </div>
    </main>
  )
}

export default Layout
