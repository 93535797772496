import { FC, useState } from 'react'
import { FormDialog, Button } from '@mondra/ui-components'
import InputItem from '../../../../components/InputItem'
import { useFormik } from 'formik'
import { sortDropdown } from '../../../../shared/formFunctions'
import { resourceSourscesComponentSchema } from '../utils'
import { addLocalPopUpNote } from '../../../../shared/noteFunctions'

interface ComponentFormProps {
  resourceSources: any[]
  addComponent: (variables?: any) => void
}

const ComponentForm: FC<ComponentFormProps> = ({ addComponent, resourceSources }) => {
  const [openForm, setOpenForm] = useState(false)

  const initialValues = {
    resourceSourceId: 0,
    quantity: 0,
    quantityNotes: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resourceSourscesComponentSchema,
    onSubmit: values => {
      values.resourceSourceId = +values.resourceSourceId
      addComponent(values)
      setTimeout(() => setOpenForm(false), 500)
    },
  })

  const onCreateComponent = () => {
    setOpenForm(true)
    formik.setValues(initialValues)
    formik.setTouched({}, false)
  }

  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className="mt-5"
        onClick={onCreateComponent}
      >
        Create component
      </Button>
      <FormDialog
        title="Create component"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              key={
                formik.values.resourceSourceId
                  ? formik.values.resourceSourceId + Math.random()
                  : Math.random()
              }
              name="Resource & Source Name"
              description="Select your resource & source name"
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={formik.values.resourceSourceId ? formik.values.resourceSourceId : -1}
              formikName="resourceSourceId"
              options={sortDropdown(
                resourceSources,
                formik.values.resourceSourceId,
                'combinedName'
              )}
            />
            <InputItem
              key={
                formik.values.resourceSourceId
                  ? formik.values.resourceSourceId + Math.random()
                  : Math.random()
              }
              name="Resource & Source ID"
              description="Select your resource & source ID"
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={formik.values.resourceSourceId ? formik.values.resourceSourceId : -1}
              formikName="resourceSourceId"
              options={sortDropdown(resourceSources, formik.values.resourceSourceId, 'combinedId')}
            />
            <InputItem
              name="Quantity Used"
              description="Please provide the quantity of the resource source in the displayed unit"
              type="input"
              formik={formik}
              showAsterisk
              addonTitle={
                resourceSources.find(el => el.id === +formik.values.resourceSourceId)?.unit
              }
              onChange={formik.handleChange}
              defaultValue={formik.values.quantity || ''}
              formikName="quantity"
              addLocal={params =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.quantityNotes.length,
                  ...params,
                })
              }
              notes={formik.values.quantityNotes || []}
            />
          </div>
        </form>
      </FormDialog>
    </>
  )
}

export default ComponentForm
