import { gql } from '@apollo/client'

export const GET_ALL_SOURCES = gql`
  query AllPieces(
    $str: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [SourceSortInput!]
  ) {
    source: __type(name: "Source") {
      fields {
        name
        description
      }
    }
    sources(
      first: $first
      last: $last
      after: $after
      before: $before
      where: { id: { gt: 0 }, or: [{ name: { contains: $str } }, { sourceId: { contains: $str } }] }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          sourceId
          name
          created
        }
        cursor
      }
    }
  }
`
