import React, { FC, useEffect, useState, EffectCallback, useMemo } from 'react'
import { useAppDispatch } from '../../../hooks/redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Breadcrumbs, Button, Icon, LottieLoader, Notification } from '@mondra/ui-components'
import { useAppSelector } from '../../../hooks/redux'
import { useFormik } from 'formik'
import { fetchSpecialIngredient, getConfiguration } from './splecialIngredientActions'
import { getFieldAudit } from '../../../redux/action-creators/auditActions'
import { sortDropdown, transformFormValues } from '../../../shared/formFunctions'
import { formSchema } from './utils'
import {
  updateSpecialIngredients,
  createSpecialIngredients,
  getProcessedDropdowns,
  getProcessedClass,
  addSpecialIngredientCategory,
  updateSpecialPath,
  publishPath,
  unpublishPath,
} from './api'

import { usePrompt } from '../../../hooks/usePrompt'

import { handlePublishStatus, setDefaultIngredient } from '../../../redux/specialIngredientSlice'

import InputItem from '../../../components/InputItem'

import './index.scss'
import SelectCreatable from '../../../components/CreatableSelect'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'
import PackagingForm from '../../../components/Packaging/Packaging'
import Collapsible from 'react-collapsible'
import ActivityRightPanel from '../../../components/ActivityRightPanel'
import { ActivityTypes } from '../../../const'

interface Dropdown {
  id: number
  code: string
  name: string
  state: number
}

const SpecialIngredient: FC = ({}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const { loading, ingredient, configuration } = useAppSelector(
    state => state.specialIngredientSlice
  )
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [showError, setShowError] = useState<string>('')
  const [errors, setErrors] = useState<[any, string[]][]>()
  const [showPublished, setShowPublished] = useState<boolean>(false)
  const [classList, setClassList] = useState<[] | Dropdown[]>([])
  const [category, setCategory] = useState<[] | Dropdown[]>([])
  let ingredientId = searchParams.get('itemId') || ''
  const isNew = ingredientId === 'new'

  useEffect((): ReturnType<EffectCallback> => {
    if (ingredientId == '') {
      navigate('/not-found')
    }
    if (isNew) {
      dispatch(getConfiguration('0'))
      getProcessedClass().then(res => setClassList(res.data))
    }
    if (!isNew) {
      dispatch(getConfiguration(ingredientId))
      dispatch(fetchSpecialIngredient(ingredientId, () => navigate('/special-ingredients')))
    }
    return () => {
      dispatch(setDefaultIngredient())
    }
  }, [isNew])

  useEffect(() => {
    formik.setValues({ ...ingredient })
  }, [ingredient])

  const addProcess = (values, array) => {
    formik.setValues({
      ...formik.values,
      [array]: Array.isArray(values)
        ? [...formik.values[array], ...values]
        : [...formik.values[array], values],
    })
  }

  const deleteProcess = (idx, array) => {
    const newComponents = formik.values[array].filter((el, index) => index != +idx)
    formik.setValues({
      ...formik.values,
      [array]: newComponents,
    })
  }

  const handleChangeItemsNumber = (index: number) => (event: any) => {
    var parsed = parseInt(event.target.value)
    var itemsNumber = isNaN(parsed) ? undefined : parsed
    const updatedPieces = formik.values.packagingPieces.map(piece => {
      if (piece.order === index) {
        return {
          ...piece,
          itemsNumber: itemsNumber,
        }
      }
      return piece
    })
    formik.setValues({
      ...formik.values,
      packagingPieces: updatedPieces,
    })
  }

  const isItemsNumberValid = (index: number) => {
    return (
      formik.values.packagingPieces[index]?.itemsNumber !== undefined &&
      (formik.values.packagingPieces[index]?.itemsNumber ?? 0) > 0
    )
  }

  const onAdd = () => {
    formik.setValues({
      ...formik.values,
      packagingPieces: [
        ...formik.values.packagingPieces,
        {
          packagingPieceId: 0,
          packagingType: 1,
          order: formik.values.packagingPieces.length,
          itemsNumber: null,
        } as any,
      ],
    })
  }

  function canSaveForm(formik) {
    return (
      formik.values.name === '' ||
      formik.isSubmitting ||
      formik.values.packagingPieces.filter(
        f =>
          f.packagingType === 1 &&
          (f.itemsNumber === undefined ||
            f.itemsNumber === null ||
            f.itemsNumber === 0 ||
            !configuration.packagingPieces.some(
              el => el.id === f.packagingPieceId || el.id.toString() === f.packagingPieceId
            ))
      ).length !== 0
    )
  }

  const formik = useFormik({
    initialValues: {
      ...ingredient,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (ingredient.state === 0) {
        publishPath('functionalunit', ingredientId)
          .then(() => {
            dispatch(handlePublishStatus(1))
            setShowPublished(true)
            setTimeout(() => {
              setShowPublished(false)
            }, 4000)
          })
          .catch(error => setShowError(error.response.data.title))
      } else {
        unpublishPath('functionalunit', ingredientId)
          .then(() => {
            dispatch(handlePublishStatus(0))
            setShowPublished(true)
            setTimeout(() => {
              setShowPublished(false)
            }, 4000)
          })
          .catch(error => setShowError(error.response.data.title))
      }
    },
  })

  const saveDraft = async e => {
    e.preventDefault()
    const { values } = formik

    const tValues = transformFormValues(values)

    for (let i = 0; i < tValues.packagingPieces.length; i++) {
      if (typeof tValues.packagingPieces[i].packagingPieceId === 'string') {
        tValues.packagingPieces[i].packagingPieceId = +tValues.packagingPieces[i].packagingPieceId
      }
    }
    if (isNew) {
      try {
        await createSpecialIngredients(tValues).then(() => {
          navigate('/special-ingredients')
        })
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId))
        } else {
          setShowError(
            typeof error.response.data === 'string'
              ? error.response.data
              : typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          )
          setTimeout(() => {
            setShowError('')
          }, 5000)
          setErrors(
            typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          )
        }
      }
    } else {
      try {
        await updateSpecialIngredients(ingredientId, tValues)
          .then(() =>
            dispatch(fetchSpecialIngredient(ingredientId, () => navigate('/special-ingredients')))
          )
          .then(() => {
            setShowNotification(true)
            setTimeout(() => {
              setShowNotification(false)
            }, 5000)
          })
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId))
        } else {
          setShowError(
            typeof error.response.data === 'string'
              ? error.response.data
              : typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          )
          setTimeout(() => {
            setShowError('')
          }, 5000)
          setErrors(
            typeof error.response.data.errors === 'string'
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          )
        }
      }
    }
  }

  usePrompt(
    'There are unsaved changes, do you want to leave?',
    ingredientId !== 'new' ? JSON.stringify(ingredient) != JSON.stringify(formik.values) : false
  )

  const primaryPiece = useMemo(
    () => configuration.packagingPieces?.filter(pp => pp.packagingType == 0),
    [configuration]
  )
  const distributionPiece = useMemo(
    () => configuration.packagingPieces?.filter(pp => pp.packagingType == 1),
    [configuration]
  )

  return (isNew && classList.length != 0) || loading !== true || isNew ? (
    <>
      <div className="piece content">
        {showError !== '' && (
          <div className={'piece-notification'}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={'piece-notification'}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={'piece-notification'}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mb-3 mt-3">
          <Breadcrumbs
            routes={[
              {
                path: '/special-ingredients',
                title: 'Special Ingredients Table',
              },
              {
                path: `/special-ingredients/ingredient?itemId=${ingredientId}`,
                title: 'Special Ingredient',
              },
            ]}
          />
        </div>
        {ingredientId !== 'new' && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  ingredient.state === 0 ? 'bg-red-500' : 'bg-emerald-500'
                }`}
              >
                {ingredient.state === 0 ? 'Not Published' : 'Published'}
              </div>
              {ingredient?.lastPublishedDate ? (
                <span className={'inputItem-header'}>
                  {`Last published date: ${new Date(ingredient.lastPublishedDate).toLocaleString()}
                  `}
                </span>
              ) : (
                ''
              )}
            </div>
          </>
        )}
        <div>
          <h5 className="mb-5 mt-5">Indicators information</h5>
          <ul>
            <li className="mb-5 flex">
              <Icon type="warning" className="mr-3 text-rose-700" /> - this indicator shows you that
              field value is not published. Press it for publishing field value (only for Managers)
            </li>
            <li className="mb-5 flex">
              <Icon type="checkmarkOutline" className="mr-3 text-emerald-700" /> - this indicator
              shows you that field value is published. Press it for unpublishing field value (only
              for Managers)
            </li>
            {ingredientId !== 'new' && (
              <li className="flex">
                <Icon type="informationSquare" className="mr-3 text-green-200" /> - press this
                button if you want to show audit information of the field
              </li>
            )}
          </ul>
        </div>
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={'mt-5'}>Special Ingredient</h5>
          {isNew ? (
            <div className="piece-content-inputs">
              <InputItem
                name="Class"
                description=""
                type="select"
                defaultValue={formik.values.classId}
                showAsterisk
                onChange={e => {
                  getProcessedDropdowns(`category?classId=${(e.target as any).value}`).then(res =>
                    setCategory(res.data)
                  )
                  formik.setFieldValue('classId', +(e.target as any).value)
                }}
                formikName="classId"
                options={classList.map(el => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
              <SelectCreatable
                name="Category"
                create={true}
                formik={formik}
                showAsterisk
                disabled={category.length === 0}
                formikName="categoryId"
                onCreateOption={newValue =>
                  addSpecialIngredientCategory({
                    name: newValue,
                    classId: 1,
                  }).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => {
                      setCategory(res.data)
                      formik.setFieldValue('categoryId', res.data[res.data.length - 1].id)
                    })
                  )
                }
                options={category.map(el => ({
                  label: el.name,
                  value: el.id,
                }))}
                unpublished={category.filter(el => el.id === formik.values.categoryId)[0]?.state}
                code={category.filter(el => el.id === formik.values.categoryId)[0]?.code}
                onPublishClick={() => {
                  publishPath('category', formik.values.categoryId).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => setCategory(res.data))
                  )
                }}
                onUnpublishClick={() => {
                  unpublishPath('category', formik.values.categoryId).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => setCategory(res.data))
                  )
                }}
                onEditClick={(name, code) => {
                  updateSpecialPath('category', {
                    name,
                    code,
                    classId: 1,
                    id: formik.values.categoryId,
                  }).then(() =>
                    getProcessedDropdowns(`category?classId=1`).then(res => setCategory(res.data))
                  )
                }}
              />
              <InputItem
                name="Special Ingredient Name"
                description=""
                type="input"
                disabled={formik.values.categoryId === 0}
                formik={formik}
                showAsterisk
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                formikName="name"
              />
            </div>
          ) : (
            <div className="piece-content-inputs">
              <InputItem
                name="Class"
                description=""
                type="input"
                formik={formik}
                showAsterisk
                onChange={formik.handleChange}
                disabled={true}
                auditHistory={true}
                defaultValue={configuration.path.class}
                formikName="width"
                onGetAuditClick={() =>
                  dispatch(
                    getFieldAudit(
                      'Taxonomy/SpecialIngredient',
                      'class',
                      configuration.path?.classId
                    )
                  )
                }
              />
              <InputItem
                name="Category"
                description=""
                type="input"
                formik={formik}
                showAsterisk
                onChange={formik.handleChange}
                disabled={true}
                defaultValue={configuration.path.category}
                formikName="width"
                unpublished={configuration.path.categoryState}
                edit={false}
                auditHistory={true}
                code={configuration.path.categoryCode}
                onPublishClick={() => {
                  publishPath('category', configuration.path.categoryId).then(() =>
                    dispatch(getConfiguration(ingredientId))
                  )
                }}
                onUnpublishClick={() => {
                  unpublishPath('category', configuration.path.categoryId).then(() =>
                    dispatch(getConfiguration(ingredientId))
                  )
                }}
                onEditClick={(name, code) => {
                  updateSpecialPath('category', {
                    name,
                    code,
                    classId: configuration.path.classId,
                    id: configuration.path.categoryId,
                  }).then(() => dispatch(getConfiguration(ingredientId)))
                }}
                onGetAuditClick={() =>
                  dispatch(
                    getFieldAudit(
                      'Taxonomy/SpecialIngredient',
                      'category',
                      configuration.path?.categoryId
                    )
                  )
                }
              />
              <InputItem
                name="Special Ingredient Name"
                description=""
                type="input"
                formik={formik}
                showAsterisk
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                formikName="name"
              />
            </div>
          )}
          <h5 className={'mt-5'}>Unit info</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="External code"
              description=""
              type="input"
              disabled
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ''}
              formikName="code"
            />
            <InputItem
              name="Special Ingredient Name Aliases"
              description=""
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.functionalUnitProxy || ''}
              formikName="functionalUnitProxy"
            />
          </div>
          <div className={'piece-content-collapsible'}>
            <h5 className={'mt-5'}>Transport and Storage</h5>
            <div className="piece-content-inputs">
              <InputItem
                name="Storage/Transport condition"
                description=""
                type="select"
                formik={formik}
                defaultValue={formik.values.transportCondition}
                onChange={formik.handleChange}
                formikName="transportCondition"
                options={sortDropdown(
                  configuration.transportCondition,
                  formik.values.transportCondition || -1,
                  'description'
                )}
              />

              <InputItem
                name="Storage Density Type"
                description=""
                type="select"
                formik={formik}
                defaultValue={formik.values.storageFoodCategory}
                onChange={formik.handleChange}
                formikName="storageFoodCategory"
                options={sortDropdown(
                  configuration.storageFoodCategory,
                  formik.values.storageFoodCategory || -1,
                  'description'
                )}
              />

              <InputItem
                name="Storage duration in hours"
                description=""
                type="input"
                addonTitle="h"
                onChange={formik.handleChange}
                formikName="storageDurationInHours"
                formik={formik}
                defaultValue={formik.values.storageDurationInHours}
              />
            </div>
          </div>
          <h5 className={'mt-5'}>Storage References</h5>
          <h6>Duration</h6>
          <div className={'piece-content-inputs mb-5'}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                formik.values.secondaryDataReferenceSourceType ?? -1,
                'description'
              )}
            />
          </div>
          <div className={'piece-content-inputs mb-5'}>
            <InputItem
              name="Reference Author Name"
              description="Please indicate author of the referene"
              type="input"
              onChange={formik.handleChange}
              formikName="referenceAuthorName"
              formik={formik}
              defaultValue={formik.values.referenceAuthorName || ''}
            />
            <InputItem
              name="Reference Link"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="referenceLink"
              formik={formik}
              defaultValue={formik.values.referenceLink || ''}
            />
            <InputItem
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ''}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referenceDataPublicationYear || ''}
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry ? formik.values.referenceSourcingCountry : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                'countryName'
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country which most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={formik.values.referenceSourcingCountry || -1}
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                'countryISO'
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={'w-full'}
            defaultValue={formik.values.dataQualityComment || ''}
            formikName="dataQualityComment"
          />
          <h6>Food Category</h6>
          <div className={'piece-content-inputs mb-5'}>
            <InputItem
              name="Reference Link"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="storageReferenceLink"
              formik={formik}
              defaultValue={formik.values.storageReferenceLink || ''}
            />
          </div>
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map(err => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className={'piece-content-collapsible'}>
            <h5 className={'mt-5'}>Packaging</h5>
            {formik.values.packagingPieces?.length === 0 && (
              <span className="text-rose-600">You should have at least one packaging piece</span>
            )}
            <br />
            {formik.values.packagingPieces?.length > 0 && (
              <Collapsible
                trigger={
                  <div className="collapsible_trigger">
                    <span>
                      {
                        configuration.packagingPieces.find(
                          p => p.id == formik.values.packagingPieces[0]?.packagingPieceId
                        )?.displayName
                      }
                    </span>
                    <Icon className="collapsible_icon" type="chevronDown" />
                  </div>
                }
              >
                <div className="piece-content-inputs">
                  <InputItem
                    name="Primary Packaging piece"
                    className="w-4/6"
                    description=""
                    type="select"
                    formik={formik}
                    onChange={formik.handleChange}
                    defaultValue={formik.values.packagingPieces[0]?.packagingPieceId}
                    formikName={`packagingPieces[${0}].packagingPieceId`}
                    options={sortDropdown(primaryPiece, 0, 'displayName')}
                  />
                  {formik.values.packagingPieces
                    .filter(f => f.packagingType === 1)
                    ?.map((item, index) => (
                      <div className="flex w-full items-end justify-start gap-4">
                        <InputItem
                          key={formik.values.packagingPieces[index + 1].packagingPieceId}
                          className="w-fit"
                          valid={formik.values.packagingPieces[index + 1]?.packagingPieceId !== 0}
                          name={index === 0 ? 'Distribution Packaging piece' : ''}
                          description={
                            index === 0
                              ? 'Please specify the distribution packaging piece of this entry'
                              : ''
                          }
                          type="select"
                          formik={formik}
                          onChange={formik.handleChange}
                          defaultValue={formik.values.packagingPieces[index + 1]?.packagingPieceId}
                          formikName={`packagingPieces[${index + 1}].packagingPieceId`}
                          options={sortDropdown(distributionPiece, 0, 'displayName')}
                          index={index}
                          create
                        />
                        <InputItem
                          name={index === 0 ? 'Items Number' : ''}
                          description={index === 0 ? 'Number previous items' : ''}
                          onChange={handleChangeItemsNumber(index + 1)}
                          type="input"
                          valid={isItemsNumberValid(index + 1)}
                          defaultValue={formik.values.packagingPieces[index + 1]?.itemsNumber}
                          index={index}
                        />
                        <Button
                          variant="secondary"
                          iconType="close"
                          isOnlyIcon
                          onClick={() => deleteProcess(index + 1, 'packagingPieces')}
                        />
                      </div>
                    ))}
                </div>
                <div className="mt-6 flex w-full items-center justify-end gap-4">
                  <Button
                    iconType="add"
                    onClick={onAdd}
                    disabled={
                      formik.values.packagingPieces.filter(f => f.packagingType === 0).length === 0
                    }
                  >
                    Distribution packaging
                  </Button>
                  <Button
                    onClick={() =>
                      formik.setValues({
                        ...formik.values,
                        packagingPieces: [],
                      })
                    }
                    variant="danger"
                    iconType="close"
                  >
                    Delete
                  </Button>
                </div>
              </Collapsible>
            )}
            <PackagingForm
              addProcess={addProcess}
              disabled={formik.values.packagingPieces?.length > 0}
              packagingPieces={configuration.packagingPieces}
            />
          </div>
          <div className="piece-actions">
            {sessionStorage.getItem('permission') == 'true' && !isNew && (
              <>
                <Button
                  type="submit"
                  variant="tertiary"
                  size="md"
                  className="w-32"
                  disabled={
                    canSaveForm(formik) ||
                    JSON.stringify(ingredient) != JSON.stringify(formik.values) ||
                    configuration.path.categoryState === 0
                  }
                >
                  {ingredient.state === 0 ? 'Publish' : 'Unpublish'}
                </Button>
              </>
            )}
            <Button
              variant="primary"
              size="md"
              className="w-32"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
            {JSON.stringify(ingredient) != JSON.stringify(formik.values) && (
              <b className="absolute bottom-0 text-sm text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={ingredientId}
        activityType={ActivityTypes.SpecialIngredient}
        auditCreatedBy={ingredient.researcherName || ''}
        auditLastUpdatedBy={ingredient.lastUpdatedName}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
        hideCompanyselect
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: '10.25rem', width: '10.25rem' }}
      />
    </div>
  )
}

export default SpecialIngredient
