import { gql } from '@apollo/client'

export const GET_ALL_WATER_SCARCITY = gql`
  query AllPieces(
    $str: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [WaterScarcityGraphQlSortInput!]
  ) {
    waterScarcityfields: __type(name: "WaterScarcityGraphQl") {
      fields {
        name
        description
      }
    }
    waterScarcity(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { countryName: { contains: $str } }
          { isoAlpha2: { contains: $str } }
          { isoAlpha3: { contains: $str } }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          cfAwareAgri
          cfAwareNonNullAgri
          countryName
          isoAlpha2
          isoAlpha3
        }
        cursor
      }
    }
  }
`
