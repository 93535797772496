import {
  ISpecialIngredient,
  IConfiguration,
} from './../modules/special-ingredients/special-ing/types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
interface IState {
  ingredient: ISpecialIngredient
  loading: boolean
  error: string
  configuration: IConfiguration
}

const initialState: IState = {
  configuration: {
    path: {
      class: '',
      category: '',
      classId: 0,
      categoryId: 0,
      classState: 0,
      categoryState: 0,
      categoryCode: null,
    },
    packagingPieces: [],
    transportCondition: [],
    storageFoodCategory: [],
    regions: [],
    countries: [],
    secondaryDataReferenceSourceType: [],
  },
  ingredient: {
    id: 0,
    userEmail: '',
    lastUpdatedName: '',
    lastPublishedDate: '',
    researcherName: '',
    researcherEmail: '',
    updated: '',
    state: 0,
    created: '',
    functionalUnitProxy: '',
    code: null,
    name: '',
    classId: -1,
    categoryId: -1,
    transportCondition: -1,
    storageFoodCategory: -1,
    storageDurationInHours: null,
    packagingPieces: [],
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
    secondaryDataReferenceSourceType: null,
    referenceAuthorName: null,
    referenceLink: null,
    storageReferenceLink: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: null,
    referenceSourcingCountry: null,
    dataQualityComment: null,
  },
  loading: true,
  error: '',
}

const specialIngredientSlice = createSlice({
  name: 'specialIngredient',
  initialState,
  reducers: {
    specialRequest(state) {
      state.loading = true
    },
    specialSuccess(state, action: PayloadAction<ISpecialIngredient>) {
      state.loading = false
      state.ingredient = action.payload
    },
    specialFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.ingredient.state = action.payload
    },
    setDefaultIngredient: () => initialState,
  },
})

export default specialIngredientSlice.reducer
export const {
  specialRequest,
  specialSuccess,
  specialFailure,
  handlePublishStatus,
  setDefaultIngredient,
  configuration,
} = specialIngredientSlice.actions
