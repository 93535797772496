import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Breadcrumbs, Button, Input } from '@mondra/ui-components'
import { useQuery } from '@apollo/client'
import { IProcess } from './types'
import { getProcessingVars } from './helpers'
import { filterEnums, getTableData } from '../../../shared/gridFunctions'
import { GET_ALL_PROCESSES } from '../../../query/processing-activity'
import Table from '../../../components/Table'

import './index.scss'

const ProcessingActivity: FC = () => {
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<IProcess[]>([])
  const [columnDefs, setColumn] = useState([])
  const [tableSearch, setTableSearch] = useState<string | null>(null)
  const [debouncedTableSearch, setDebouncedTableSearch] = useState(tableSearch)
  const [totalCount, setTotalCount] = useState<string>('')
  const [endCursor, setEndCursor] = useState<string | null>(null)
  const [startCursor, setStartCursor] = useState<string | null>(null)
  const [processingVars, setProcessingVars] = useState<any>(getProcessingVars)
  const [order, setOrder] = useState([{ id: 'ASC' }])

  const { data, loading, error, refetch } = useQuery(GET_ALL_PROCESSES, {
    variables: {
      str: '',
      first: 100,
      last: null,
      order: order,
      ...getProcessingVars,
    },
  })

  const anyRefetch = (variables?: any): Promise<any> => {
    return refetch(variables)
  }

  const setCursors = () => {
    setEndCursor(data.processingActivities.pageInfo.endCursor)
    setStartCursor(data.processingActivities.pageInfo.startCursor)
  }

  useEffect(() => {
    const timer = setTimeout(() => setTableSearch(debouncedTableSearch), 1000)
    return () => clearTimeout(timer)
  }, [debouncedTableSearch])

  useEffect(() => {
    refetch()
    getTableData(
      data,
      loading,
      setTableData,
      setColumn,
      setTotalCount,
      setCursors,
      '/processing-activity/process',
      'processingActivities',
      'processingActivity'
    )
  }, [data])

  useEffect(() => {
    if (tableSearch != null || !order[0].id) {
      if (!loading) {
        setProcessingVars({
          ...filterEnums(tableSearch, getProcessingVars, data),
        })
        anyRefetch({
          str: tableSearch || '',
          ...filterEnums(tableSearch, getProcessingVars, data),
          first: 100,
          after: null,
          before: null,
          last: null,
          order: order,
        })
      }
    }
  }, [tableSearch, order, loading])

  return (
    <div className={'packaging'}>
      <div style={{ maxWidth: '50rem' }} className={'mb-4'}>
        <p className={'mt-3 decoration-gray-600'}>
          <Breadcrumbs
            routes={[
              {
                path: '/processing-activity',
                title: 'Processing Activity Table',
              },
            ]}
          />
        </p>
        <h2 className={'mb-5 mt-3 text-2xl'}>Processing Activity</h2>
        <div className="packaging-actions">
          <Input
            onChange={e => setDebouncedTableSearch(e.target.value)}
            iconType="search"
            placeholder="Search"
          />
          <Button
            onClick={() => navigate('/processing-activity/process?itemId=new')}
            variant="primary"
          >
            Add Process
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        tableData={tableData}
        columnDefs={columnDefs}
        totalNumber={totalCount}
        endCursor={endCursor}
        startCursor={startCursor}
        refetch={refetch}
        defaultVars={processingVars || getProcessingVars}
        setCursors={setCursors}
        tableSearch={tableSearch}
        setOrder={setOrder}
        order={order}
        suppressRowClickSelection
      />
    </div>
  )
}

export default ProcessingActivity
