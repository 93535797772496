import { gql } from '@apollo/client'

export const GET_FACTORS_ALL = gql`
  query AllPieces(
    $str: String
    $state: StateEnumOperationFilterInput
    $productType: NullableOfProductTypeEFEnumOperationFilterInput
    $functionalUnit: NullableOfFunctionalUnitEnumOperationFilterInput
    $dataOrigin: NullableOfDataOriginEnumOperationFilterInput
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $farmDataCalculator: NullableOfFarmDataCalculatorEnumOperationFilterInput
    $pnNExcelCalculator: NullableOfPnNExcelCalculatorEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [EmissionFactorsGraphQlSortInput!]
  ) {
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }

    productType: __type(name: "ProductTypeEFEnum") {
      name
      enumValues {
        name
        description
      }
    }

    functionalUnit: __type(name: "FunctionalUnitEnum") {
      name
      enumValues {
        name
        description
      }
    }
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    dataOrigin: __type(name: "DataOriginEnum") {
      name
      enumValues {
        name
        description
      }
    }

    farmDataCalculator: __type(name: "FarmDataCalculatorEnum") {
      name
      enumValues {
        name
        description
      }
    }

    pnNExcelCalculator: __type(name: "PnNExcelCalculatorEnum") {
      name
      enumValues {
        name
        description
      }
    }

    emissionFactor: __type(name: "EmissionFactorsGraphQl") {
      fields {
        name
        description
      }
    }

    emissionFactors(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { researcherName: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { referenceSourcingCountry: { contains: $str } }
          { referenceSourcingCountry: { contains: $str } }
          { referenceCountryISOCode: { contains: $str } }
          { productName: { contains: $str } }
          { productGroup: { contains: $str } }
          { productNameAtSource: { contains: $str } }
          { countryISOCode: { contains: $str } }
          { countryName: { contains: $str } }
          { regionIdentifier: { contains: $str } }
          { regionStateName: { contains: $str } }
          { coreVersionRelease: { contains: $str } }
          { externalSource: { contains: $str } }
          { hestiaProduceId: { contains: $str } }
          { hestiaSubmissionId: { contains: $str } }
          { calculatorVersion: { contains: $str } }
          { clickUpId: { contains: $str } }
          { referenceStudy: { contains: $str } }
          { referenceTechnologyType: { contains: $str } }
          { code: { contains: $str } }
          { pnNExcelCalculator: $pnNExcelCalculator }
          { farmDataCalculator: $farmDataCalculator }
          { dataOrigin: $dataOrigin }
          { functionalUnit: $functionalUnit }
          { productType: $productType }
          { state: $state }
          { secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          state
          productType
          productGroup
          productName
          clickUpId
          countryName
          regionStateName
          productionSystem
          functionalUnit
          dataOrigin
          farmDataCalculator
          landUse
          biodiversity
          carbon
          eutrophication
          waterUsage
          waterUsageIncludingWasterScarcity
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          dataQualityCompletenessScore
          referenceDataPublicationYear
          referenceTechnologyType
          referenceSourcingCountry
          lastPublishedDate
          researcherName
          isVerified
          verifiedOn
          updated
          lastUpdatedName
        }
        cursor
      }
    }
  }
`
