import React, { FC, useState } from 'react'
import { Button, Icon, LottieLoader } from '@mondra/ui-components'
import { useAppSelector } from '../../hooks/redux'
import './index.scss'

interface AuditNotes {
  previousNote: null | string
  currentNote: string | null
  previousReferenceNote: null | string
  currentReferenceNote: null | string
  previousReference: null | string
  currentReference: null | string
  fullName: string
  created: string
}

interface AuditItemPropsFields {
  field: string
  previous: string | null
  current: string
  notes: AuditNotes[]
}
interface AuditItemProps {
  name: string | null
  date: string
  status: string
  stage: string
  fields: AuditItemPropsFields[]
}

export const AuditItem: FC<AuditItemProps> = ({ name, date, status, fields, stage }) => {
  return (
    <div className="audit-item">
      <div className="audit-item-author">
        <h5>{name}</h5>
        <span>{date}</span>
        {stage === 'PackagingPieceComponentAudit' && <div>Component</div>}
        <b>{status}</b>
      </div>
      <h6>From</h6>
      <div className="audit-item-from">
        {fields.map(el => {
          if (el.notes.length === 0) {
            return (
              <div>
                <b>{el.field}:</b> {el.previous}
              </div>
            )
          } else {
            return el.notes.map(note => (
              <div>
                <b>{el.field}:</b> {note.previousNote || note.previousReferenceNote}{' '}
                {note.previousReference && `; ${note.previousReference}`}
              </div>
            ))
          }
        })}
      </div>
      <h6>To</h6>
      <div className="audit-item-to">
        {fields.map(el => {
          if (el.notes.length === 0) {
            return (
              <div>
                <b>{el.field}:</b> {el.current}
              </div>
            )
          } else {
            return el.notes.map(note => (
              <div>
                <b>{el.field}:</b> {note.currentNote || note.currentReferenceNote}{' '}
                {note.currentReference && `; ${note.currentReference}`}
              </div>
            ))
          }
        })}
      </div>
    </div>
  )
}

interface AuditProps {
  children?: React.ReactNode
  createdBy: string
  lastUpdatedName: string | null
  onClick: any
  showAuditButton?: boolean
}

const Audit: FC<AuditProps> = ({
  children,
  createdBy,
  lastUpdatedName,
  onClick,
  showAuditButton = true,
}) => {
  const [showAudit, setShowAudit] = useState(false)
  const { audit, loading } = useAppSelector(state => state.auditSlice)

  const auditList = audit.map(el =>
    el.changes.map(change => (
      <AuditItem
        key={change.updatedDate}
        stage={change.stage}
        name={el.userName || el.userEmail}
        date={change.updatedDate}
        fields={change.fields}
        status={change.operationType}
      />
    ))
  )

  return (
    <div>
      {showAudit && (
        <div className="audit-full">
          <div className="audit-full-header">
            <h5>History</h5>
            <Icon className={'cursor-pointer'} type="close" onClick={() => setShowAudit(false)} />
          </div>
          <p>View and manage versions and track changes here.</p>
          <hr />
          {loading ? (
            <LottieLoader
              lottieType="butterflyLottie"
              style={{
                height: '100px',
                width: '100px',
                marginLeft: '200px',
                marginTop: '100px',
              }}
            />
          ) : (
            auditList
          )}
        </div>
      )}
      <div className="audit-record">
        <h5>Record Information</h5>
        <div className="audit-record-content">
          {lastUpdatedName && (
            <span>
              Updated by: <b>{lastUpdatedName}</b>
            </span>
          )}
          <span>
            Created by: <b>{createdBy}</b>
          </span>
        </div>
        <Button
          variant="tertiary"
          size="md"
          hidden={!showAuditButton}
          onClick={() => {
            onClick()
            setShowAudit(true)
          }}
          className="audit-record-button"
        >
          View History
        </Button>
      </div>
    </div>
  )
}

export default Audit
