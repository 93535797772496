import { FC, useState, useEffect } from 'react'
import { Breadcrumbs, Input } from '@mondra/ui-components'
import { GET_ALL_RESOURCES_READONLY } from '../../../query/resourcesSourcesReadonly'
import { useQuery } from '@apollo/client'
import { IResourcesSources } from './types'
import { getResourcesSourcesReadonlyVars } from './helpers'
import { filterEnums, getTableData } from '../../../shared/gridFunctions'
import Table from '../../../components/Table'

import './index.scss'

const defaultColumnDefination = [
  {
    field: 'isEmissionToAir',
    headerName: 'Is emission',
  },
  {
    field: 'isForWaste',
    headerName: 'Is waste treatment',
  },
]
const ResourcesSources: FC = () => {
  const [tableData, setTableData] = useState<IResourcesSources[]>([])
  const [columnDefs, setColumn] = useState([])
  const [tableSearch, setTableSearch] = useState<string | null>(null)
  const [debouncedTableSearch, setDebouncedTableSearch] = useState(tableSearch)
  const [totalCount, setTotalCount] = useState<string>('')
  const [endCursor, setEndCursor] = useState<string | null>(null)
  const [startCursor, setStartCursor] = useState<string | null>(null)
  const [resourcesVars, setResourcesVars] = useState<any>(getResourcesSourcesReadonlyVars)
  const [order, setOrder] = useState([{ id: 'ASC' }])

  const { data, loading, error, refetch } = useQuery(GET_ALL_RESOURCES_READONLY, {
    variables: {
      str: '',
      first: 100,
      last: null,
      order: order,
      ...getResourcesSourcesReadonlyVars,
    },
  })

  const anyRefetch = (variables?: any): Promise<any> => {
    return refetch(variables)
  }

  const setCursors = () => {
    setEndCursor(data.resourcesSources.pageInfo.endCursor)
    setStartCursor(data.resourcesSources.pageInfo.startCursor)
  }

  useEffect(() => {
    const timer = setTimeout(() => setTableSearch(debouncedTableSearch), 1000)
    return () => clearTimeout(timer)
  }, [debouncedTableSearch])

  useEffect(() => {
    refetch()
    getTableData(
      data,
      loading,
      setTableData,
      setColumn,
      setTotalCount,
      setCursors,
      '',
      'resourcesSources',
      'resourceSourceFields',
      defaultColumnDefination
    )
  }, [data])

  useEffect(() => {
    if (tableSearch != null || !order[0].id) {
      if (!loading) {
        setResourcesVars({ ...filterEnums(tableSearch, getResourcesSourcesReadonlyVars, data) })
        anyRefetch({
          str: tableSearch || '',
          ...filterEnums(tableSearch, getResourcesSourcesReadonlyVars, data),
          first: 100,
          after: null,
          before: null,
          last: null,
          order: order,
        })
      }
    }
  }, [tableSearch, order, loading])

  return (
    <div className={'packaging'}>
      <div style={{ width: '50rem' }} className={'mb-4'}>
        <p className={'mt-3 decoration-gray-600'}>
          <Breadcrumbs
            routes={[{ path: '/resources-sources-readonly', title: 'Resource sources Table' }]}
          />
        </p>
        <h2 className={'mb-5 mt-3 text-2xl'}>Resources sources</h2>
        <div className="packaging-actions">
          <Input
            onChange={e => setDebouncedTableSearch(e.target.value)}
            iconType="search"
            placeholder="Search"
          />
        </div>
      </div>
      <Table
        loading={loading}
        tableData={tableData}
        columnDefs={columnDefs}
        totalNumber={totalCount}
        endCursor={endCursor}
        startCursor={startCursor}
        refetch={refetch}
        defaultVars={resourcesVars || getResourcesSourcesReadonlyVars}
        setCursors={setCursors}
        tableSearch={tableSearch}
        setOrder={setOrder}
        order={order}
        suppressRowClickSelection
      />
    </div>
  )
}

export default ResourcesSources
