export const addLocalFormNote = object => {
  const { arrayName, componentIndex, key, value, formik } = object
  formik.setFieldValue(`${arrayName}[${componentIndex}].${key}`, [
    ...formik.values?.[arrayName][componentIndex]?.[key],
    value,
  ])
}

export const addLocalPopUpNote = object => {
  const { formik, key, value } = object
  formik.setValues({
    ...formik.values,
    [key]: [...formik.values[key], value],
  })
}
