import { FC, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { LottieLoader, Notification } from '@mondra/ui-components'
import jwt_decode from 'jwt-decode'
import AppRoutes from './routes'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import { getCompanies } from './redux/action-creators/companiesSlice'
import { useAppDispatch, useAppSelector } from './hooks/redux'
import { hideCorrelationIdError } from './redux/correlationIdSlice'
import './styles/index.scss'

const App: FC = () => {
  const { user, isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  const dispatch = useAppDispatch()

  const [token, setToken] = useState<string>('')
  const [ready, setReady] = useState<boolean>(false)
  const { showCorrelationIdNotification, errorId } = useAppSelector(state => state.correlationId)

  LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE}`)

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          appQueryParams: window.location.search,
          returnTo: window.location.pathname,
        },
      })
    }
    ;(async function () {
      const accessToken = await getAccessTokenSilently()
      if (accessToken) {
        setToken(accessToken)
        sessionStorage.setItem('accessToken', accessToken)
        let decoded: any = jwt_decode(accessToken)
        sessionStorage.setItem('userId', decoded.sub)
        if (decoded.permissions.includes('approve:dp_entities')) {
          sessionStorage.setItem('permission', 'true')
        } else {
          sessionStorage.setItem('permission', 'false')
        }
        dispatch(getCompanies())
        setReady(true)
      }
    })()
  }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, dispatch])

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql/`,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-api-companies': sessionStorage.getItem('companies'),
        authorization: `Bearer ${token}`,
        'X-MONDRA-APP': 'DataPlatform',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  if (!isAuthenticated || ready === false) {
    return (
      <div className="overlay">
        <LottieLoader
          lottieType="butterflyLottie"
          style={{ height: '10.25rem', width: '10.25rem' }}
        />
      </div>
    )
  }

  return ready ? (
    <ApolloProvider client={client}>
      <AppRoutes user={user} />
      {showCorrelationIdNotification && (
        <div className={'correlationContainer'}>
          <div className={'correlationError'}>
            <Notification
              type="error"
              label="Correlation ID:"
              description={`Please show this ID "${errorId}" to somebody from support!`}
              isToast={false}
              onClose={function noRefCheck() {
                dispatch(hideCorrelationIdError())
              }}
            />
          </div>
        </div>
      )}
    </ApolloProvider>
  ) : null
}

export default App
