import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ROUTES } from '../const'
import Layout from '../components/Layout'
import Packaging from '../modules/packaging-pieces/packaging-grid'
import Piece from '../modules/packaging-pieces/piece'
import NotFound from '../modules/not-found'
import TransportActivity from '../modules/transport-activity/transport-grid'
import Transport from '../modules/transport-activity/transport'
import SalesActivity from '../modules/sales-activity/sales-grid'
import ResourcesSources from '../modules/resorces-sources/resources-grid'
import Resource from '../modules/resorces-sources/resource'
import Sale from '../modules/sales-activity/sale'
import StorageActivity from '../modules/storage-activity/storage-grid'
import Storage from '../modules/storage-activity/storage'
import ProcessingActivity from '../modules/processing-activity/processing-grid'
import Process from '../modules/processing-activity/process'
import ProcessedIngredients from '../modules/processed-ingredients/processed-grid'
import SpecialIngredients from '../modules/special-ingredients/special-grid'
import Produce from '../modules/produce/produce-grid'
import Product from '../modules/product/product-grid'
import ProcessedIngredient from '../modules/processed-ingredients/processed-ing'
import SpecialIngredient from '../modules/special-ingredients/special-ing'
import ProduceIngredient from '../modules/produce/produce-ing'
import ProductGpc from '../modules/product/product-gpc'
import EmissionFactors from '../modules/emission-factors/emission-grid'
import Factor from '../modules/emission-factors/emission'
import Dashboard from '../modules/dashboard'
import WaterScarcity from '../modules/water-scarcity/water-scarcity-grid'
import Resources from '../modules/resources/resources-grid'
import Sources from '../modules/sources/sources-grid'
import ResourcesSourcesReadonly from '../modules/resourcesSourcesReadonly/resourcesSourcesReadonly-grid'

interface AppRoutesProps {
  user: any
}

const AppRoutes: FC<AppRoutesProps> = ({ user }) => {
  const {
    dashboard,
    packaging,
    piece,
    transportActivity,
    transport,
    notFound,
    saleActivity,
    sale,
    resourcesSources,
    resourcesSourcesReadonly,
    resource,
    storageActivity,
    storage,
    processingActivity,
    processing,
    processedIngredients,
    processedIngredient,
    specialIngredients,
    specialIngredient,
    produce,
    product,
    productGpc,
    produceIngredient,
    emissionFactors,
    emission,
    waterScarcity,
    resources,
    sources,
  } = ROUTES

  return (
    <Layout name={user.name}>
      <Routes>
        <Route path={dashboard} element={<Dashboard name={user.name} />} />
        <Route path={packaging} element={<Packaging />} />
        <Route path={piece} element={<Piece />} />
        <Route path={notFound} element={<NotFound />} />
        <Route path={transportActivity} element={<TransportActivity />} />
        <Route path={transport} element={<Transport />} />
        <Route path={saleActivity} element={<SalesActivity />} />
        <Route path={sale} element={<Sale />} />
        <Route path={resourcesSources} element={<ResourcesSources />} />
        <Route path={resource} element={<Resource />} />
        <Route path={storageActivity} element={<StorageActivity />} />
        <Route path={storage} element={<Storage />} />
        <Route path={processingActivity} element={<ProcessingActivity />} />
        <Route path={processing} element={<Process />} />
        <Route path={processedIngredients} element={<ProcessedIngredients />} />
        <Route path={processedIngredient} element={<ProcessedIngredient />} />
        <Route path={specialIngredients} element={<SpecialIngredients />} />
        <Route path={specialIngredient} element={<SpecialIngredient />} />
        <Route path={produce} element={<Produce />} />
        <Route path={product} element={<Product />} />
        <Route path={produceIngredient} element={<ProduceIngredient />} />
        <Route path={productGpc} element={<ProductGpc />} />
        <Route path={emissionFactors} element={<EmissionFactors />} />
        <Route path={emission} element={<Factor />} />
        <Route path={waterScarcity} element={<WaterScarcity />} />
        <Route path={resources} element={<Resources />} />
        <Route path={sources} element={<Sources />} />
        <Route path={resourcesSourcesReadonly} element={<ResourcesSourcesReadonly />} />
      </Routes>
    </Layout>
  )
}

export default AppRoutes
