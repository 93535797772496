import { AppDispatch } from '../../../redux/store'
import { getProcessingActivity, getProcessingActivityConfiguration } from './api'
import {
  processFailure,
  processRequest,
  processSuccess,
  configuration,
} from '../../../redux/processSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchProcess = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
  try {
    dispatch(processRequest())
    const { data: pieceData } = await getProcessingActivity(pieceId)
    dispatch(processSuccess(pieceData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    if (error.response.status == 400) {
      replace()
    }
    dispatch(processFailure(error.message))
  }
}

export const getConfiguration = () => async (dispatch: AppDispatch) => {
  try {
    const { data: configurationData } = await getProcessingActivityConfiguration()
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(processFailure(error.message))
  }
}
