import { IProduce } from './types'
import http from '../../../http'
import { API_URL } from '../../../const'

const PRODUCE_INGREDIENT: string = `${API_URL}/Taxonomy/Produce`

export const getProduce = (pieceId: string) =>
  http.get(`${PRODUCE_INGREDIENT}/functionalUnit/${pieceId}`)

export const updateProduce = (pieceId: string, data: IProduce) =>
  http.put(`${PRODUCE_INGREDIENT}/functionalUnit/${pieceId}`, { data })

export const deleteProduce = (pieceId: string) => http.delete(`${PRODUCE_INGREDIENT}/${pieceId}`)

export const createProduce = data => http.post(`${PRODUCE_INGREDIENT}/functionalUnit`, { data })

export const getProduceConfiguration = (pieceId: string) =>
  http.get(`${PRODUCE_INGREDIENT}/functionalunit/configuration/${pieceId}`)

export const addProduceCategory = (array, data) =>
  http.post(`${PRODUCE_INGREDIENT}/${array}`, { data })

export const updateProducePath = (path, data) =>
  http.put(`${PRODUCE_INGREDIENT}/${path}/${data.id}`, { data })

export const publishPath = (path, id) => http.post(`${PRODUCE_INGREDIENT}/${path}/publish/${id}`)
export const unpublishPath = (path, id) =>
  http.delete(`${PRODUCE_INGREDIENT}/${path}/unpublish/${id}`)

export const getProcessedClass = () => http.get(`${PRODUCE_INGREDIENT}/class`)

export const getProcessedDropdowns = (level: string) => http.get(`${PRODUCE_INGREDIENT}/${level}`)

export const getFAOItems = (faoType: number | undefined, query: string) =>
  http.get(`${API_URL}/FAO?type=${faoType}&query=${query}&limit=200`)
