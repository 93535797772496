import { ChangeEvent, FC, useState, useEffect } from 'react'
import {
  FormDialog,
  ConfirmDialog,
  Icon,
  Input,
  Select,
  Textarea,
  LottieLoader,
} from '@mondra/ui-components'
import AsyncSelect from 'react-select/async'
import Notes from '../Notes'

import { IOptionProps } from '../../types/componentTypes'
import './index.scss'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { AuditItem } from '../Audit'
import { resetFieldAudit } from '../../redux/auditSlice'

interface InputItemNotes {
  reference: string | null
  referenceNote: string | null
  note: string | null
  userId: string
  userEmail: string
  userFullName: string
  created: string
}

interface InputItemsProps {
  addonTitle?: string
  name: string
  description: string
  defaultValue?: any
  type: string
  options?: IOptionProps[]
  onChange?: (e: ChangeEvent) => void
  onPaste?: (event: any) => void
  formikName?: string
  className?: string
  notes?: InputItemNotes[] | null
  addNote?: (variables: any) => any
  addLocal?: (variables: any) => any
  create?: boolean
  formik?: any
  showAsterisk?: boolean
  disabled?: boolean
  readOnly?: boolean
  codeDisabled?: boolean
  unpublished?: number
  edit?: boolean
  auditHistory?: boolean
  valid?: boolean
  code?: string | null
  onEditClick?: (name: string | number, code: string | null) => void
  onPublishClick?: () => void
  onUnpublishClick?: () => void
  onGetAuditClick?: () => void
  loadOptions?: (inputValue: string, callback: (options: any[]) => void) => void
  index?: number
}

const InputItem: FC<InputItemsProps> = ({
  addonTitle,
  name,
  description,
  defaultValue = '',
  type,
  options = [{ value: '', label: '' }],
  onChange,
  onPaste,
  formikName = '',
  className,
  notes,
  addNote,
  addLocal,
  formik,
  showAsterisk = false,
  disabled,
  readOnly,
  codeDisabled = true,
  unpublished,
  edit,
  auditHistory,
  code = null,
  valid = true,
  onEditClick = () => {},
  onPublishClick = () => {},
  onUnpublishClick = () => {},
  onGetAuditClick = () => {},
  loadOptions = () => {},
  index = 0,
}) => {
  const [showForm, setShowForm] = useState(false)
  const [showPublish, setShowPublish] = useState(false)
  const [showUnpublish, setShowUnpublish] = useState(false)
  const [showAuditForm, setShowAuditForm] = useState(false)
  const [formValue, setFormValue] = useState<string | number>(defaultValue)
  const [currentValue, setCurrentValue] = useState<undefined | IOptionProps>(undefined)
  const [codeValue, setCodeValue] = useState<string | null>(null)
  const { fieldAudit, loadingFieldAudit } = useAppSelector(state => state.auditSlice)
  const dispatch = useAppDispatch()

  const auditList = fieldAudit.map(el =>
    el.changes.map(change => (
      <AuditItem
        key={change.updatedDate}
        stage={change.stage}
        name={el.userName || el.userEmail}
        date={change.updatedDate}
        fields={change.fields}
        status={change.operationType}
      />
    ))
  )

  useEffect(() => {
    setFormValue(defaultValue)
    setCodeValue(code)
    if (type === 'autocompleteLockup') {
      if (currentValue) {
        setCurrentValue({
          label: currentValue?.label,
          value: currentValue?.value,
        })
      } else if (defaultValue && defaultValue.value == formik.values[formikName]) {
        setCurrentValue({
          label: defaultValue.label,
          value: defaultValue.value,
        })
      }
    }
  }, [defaultValue, code])

  const isNestedProperty = /\[\d\]/g.test(formikName)
  const formatedFormikName = formikName.split('.').at(-1) || ''
  const arrayName = formikName.split(/\[\d\]/)[0]
  const validationError = isNestedProperty
    ? formik?.errors?.[arrayName]?.[index]?.[formatedFormikName]
    : formik?.errors[formikName]
  const validationTouch = isNestedProperty
    ? formik?.touched?.[arrayName]?.[index]?.[formatedFormikName]
    : formik?.touched[formikName]

  return (
    <div className={`inputItem ${className && className}`}>
      <div className="inputItem-header">
        <div>
          <div className="flex items-center gap-x-2">
            <div>
              <h3 className="whitespace-nowrap">
                {name} {showAsterisk && <span className="text-red-500">*</span>}
              </h3>
            </div>
            {edit && (
              <Icon
                onClick={() => setShowForm(true)}
                type="edit"
                className="w-5 cursor-pointer text-cyan-400"
              />
            )}
            {unpublished === 0 && (
              <Icon
                onClick={() =>
                  sessionStorage.getItem('permission') == 'true' && setShowPublish(true)
                }
                type={'warning'}
                className="w-5 cursor-pointer text-rose-700"
              />
            )}
            {unpublished === 1 && (
              <Icon
                onClick={() =>
                  sessionStorage.getItem('permission') == 'true' && setShowUnpublish(true)
                }
                type={'checkmarkOutline'}
                className="w-5 cursor-pointer text-emerald-700"
              />
            )}
            {auditHistory && (
              <Icon
                onClick={() => {
                  onGetAuditClick()
                  setShowAuditForm(true)
                }}
                type="informationSquare"
                className="w-5 cursor-pointer text-green-200"
              />
            )}
          </div>
          <p className={'mb-1 decoration-gray-600'}>{description}</p>
        </div>
        {notes && (
          <Notes
            notesName={formikName + 'Notes'}
            notes={notes}
            addNote={addNote}
            addLocal={addLocal}
          />
        )}
      </div>
      {type === 'input' && (
        <Input
          name={formikName}
          disabled={disabled}
          readOnly={readOnly}
          value={defaultValue}
          className={`mt-2 h-[2.5rem] w-full ${
            (formik && validationTouch && validationError) || !valid ? 'border-rose-500' : ''
          }`}
          addonTitle={addonTitle ? addonTitle : undefined}
          onChange={onChange}
        />
      )}
      {type === 'text' && (
        <Textarea
          name={formikName}
          disabled={disabled}
          value={defaultValue}
          className={`mb-5 mt-2 h-20 w-full ${
            (formik && formik.touched[formikName] && formik.errors[formikName]) || !valid
              ? 'border-rose-500'
              : ''
          }`}
          onChange={onChange}
          onPaste={onPaste}
        />
      )}
      {type === 'select' && (
        <Select
          noLabel="Select an option"
          name={formikName}
          onChange={onChange}
          // defaultValue={defaultValue}
          disabled={disabled}
          className={`mt-2 max-h-[2.5rem] w-full ${
            (formik && formik.touched[formikName] && formik.errors[formikName]) || !valid
              ? 'border-rose-500'
              : ''
          }`}
          value={defaultValue}
          options={options}
        />
      )}
      {type === 'autocompleteLockup' && (
        <AsyncSelect
          loadOptions={loadOptions}
          isDisabled={disabled}
          value={currentValue}
          onChange={(newValue: any, action: any) => {
            setFormValue(newValue)
            formik.setFieldValue(formikName, newValue.value)
            setCurrentValue(newValue)
          }}
          className={`mt-2 max-h-[2.5rem] w-full ${
            (formik && formik.touched[formikName] && formik.errors[formikName]) || !valid
              ? 'border-rose-500'
              : ''
          }`}
        />
      )}
      {formik && validationTouch && validationError ? (
        <span className="formik-error">{validationError}</span>
      ) : (
        ''
      )}
      <FormDialog
        title={`Edit ${name}`}
        formId="input"
        onClose={() => setTimeout(() => setShowForm(false), 500)}
        primaryBtnText="Update"
        onPrimaryButtonClick={() => {
          onEditClick(formValue, codeValue)
          setTimeout(() => setShowForm(false), 500)
        }}
        open={showForm}
      >
        <h4 className="text-base">Please check this {name} before publishing it:</h4>
        <Input
          className="mt-5"
          value={formValue}
          onChange={e => setFormValue((e.target as any).value)}
        />
        <h5 className="mt-5 text-base">Please, add the external code for this {name}:</h5>
        <Input
          disabled={codeDisabled}
          className="mt-2"
          value={codeValue || ''}
          onChange={e => setCodeValue((e.target as any).value)}
        />
      </FormDialog>
      <ConfirmDialog
        title={`Publish ${name}`}
        primaryBtnText="Publish"
        variant="primary"
        onClose={() => setTimeout(() => setShowPublish(false), 500)}
        open={showPublish}
        onPrimaryBtnClick={() => {
          onPublishClick()
          setTimeout(() => setShowPublish(false), 500)
        }}
      >
        <div className="text-lg">
          Please check all information about this {name} and publish it.
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        title={`Unpublish ${name}`}
        primaryBtnText="Unpublish"
        variant="danger"
        onClose={() => setTimeout(() => setShowUnpublish(false), 500)}
        open={showUnpublish}
        onPrimaryBtnClick={() => {
          onUnpublishClick()
          setTimeout(() => setShowUnpublish(false), 500)
        }}
      >
        <div className="text-lg">
          Are you sure you want to unpublish this {name}? Unpublishing {name} will lead to
          unpublishing all its children too.
        </div>
      </ConfirmDialog>
      <FormDialog
        title={`Audit for ${name}`}
        formId="audit"
        onClose={() => {
          setTimeout(() => {
            setShowAuditForm(false)
            dispatch(resetFieldAudit())
          }, 500)
        }}
        primaryBtnText="Confirm"
        onPrimaryButtonClick={() => {
          setTimeout(() => {
            setShowAuditForm(false)
            dispatch(resetFieldAudit())
          }, 500)
        }}
        open={showAuditForm}
      >
        {loadingFieldAudit ? (
          <LottieLoader
            lottieType="butterflyLottie"
            style={{
              height: '100px',
              width: '100px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '100px',
            }}
          />
        ) : (
          auditList
        )}
      </FormDialog>
    </div>
  )
}

export default InputItem
