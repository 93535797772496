import { IStorageForm, IConfiguration } from './../modules/storage-activity/storage/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  storage: IStorageForm
  loading: boolean
  error: string
  configuration: IConfiguration
}

const initialState: IState = {
  configuration: {
    regions: [],
    countries: [],
    enclosure: [],
    foodCategory: [],
    resourceSources: [],
    temperatureConditions: [],
    secondaryDataReferenceSourceType: [],
  },
  storage: {
    code: null,
    userId: null,
    userEmail: null,
    lastUpdatedName: null,
    companyId: null,
    lastPublishedDate: null,
    researcherName: '',
    researcherEmail: '',
    updated: null,
    state: 0,
    id: 0,
    description: '',
    temperatureConditions: -1,
    enclosure: -1,
    foodCategory: -1,
    countryId: -1,
    referenceRegionIdentifier: -1,
    resourceSources: [],
    secondaryDataReferenceSourceType: -1,
    dataQualityReliabilityScore: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: '',
    dataQualityComment: '',
    referenceSourcingCountry: -1,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  error: '',
}

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    storageRequest(state) {
      state.loading = true
    },
    storageSuccess(state, action: PayloadAction<IStorageForm>) {
      state.loading = false
      state.storage = action.payload
    },
    storageFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.storage.state = action.payload
    },
    addNote(state, action) {
      if (action.payload.index != undefined) {
        if (state.storage.resourceSources[action.payload.index][action.payload.key] == null) {
          state.storage.resourceSources[action.payload.index][action.payload.key] = []
        }
        state.storage.resourceSources[action.payload.index][action.payload.key].push(
          action.payload.value
        )
      } else {
        if (state.storage[action.payload.key] == null) {
          state.storage[action.payload.key] = []
        }
        state.storage[action.payload.key].push(action.payload.value)
      }
    },
    setDefaultStorage: () => initialState,
  },
})

export default storageSlice.reducer
export const {
  storageRequest,
  storageSuccess,
  storageFailure,
  handlePublishStatus,
  setDefaultStorage,
  addNote,
  configuration,
} = storageSlice.actions
