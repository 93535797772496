import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Breadcrumbs, Button, Input } from '@mondra/ui-components'
import { useQuery } from '@apollo/client'
import { IProcessedIngredient } from './types'
import { getProcessedIngredientVars } from './helpers'
import { filterEnums, getTableData } from '../../../shared/gridFunctions'
import Table from '../../../components/Table'
import { GET_ALL_PROCESSED_ING } from '../../../query/processed-ingredients'

const ProcessedIngredients: FC = () => {
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<IProcessedIngredient[]>([])
  const [columnDefs, setColumn] = useState([])
  const [tableSearch, setTableSearch] = useState<string | null>(null)
  const [debouncedTableSearch, setDebouncedTableSearch] = useState(tableSearch)
  const [totalCount, setTotalCount] = useState<string>('')
  const [endCursor, setEndCursor] = useState<string | null>(null)
  const [startCursor, setStartCursor] = useState<string | null>(null)
  const [processedIngredient, setProcessedIngredient] = useState<any>(getProcessedIngredientVars)
  const [order, setOrder] = useState([
    {
      class: 'ASC',
      subclass: 'ASC',
      activity: 'ASC',
      category: 'ASC',
      subcategory: 'ASC',
      functionalUnit: 'ASC',
    },
  ])

  const { data, loading, error, refetch } = useQuery(GET_ALL_PROCESSED_ING, {
    variables: {
      str: '',
      first: 100,
      last: null,
      order: order,
      ...getProcessedIngredientVars,
    },
  })

  const anyRefetch = (variables?: any): Promise<any> => {
    return refetch(variables)
  }

  const setCursors = () => {
    setEndCursor(data.taxonomyProcessedFunctionalUnits.pageInfo.endCursor)
    setStartCursor(data.taxonomyProcessedFunctionalUnits.pageInfo.startCursor)
  }

  useEffect(() => {
    const timer = setTimeout(() => setTableSearch(debouncedTableSearch), 1000)
    return () => clearTimeout(timer)
  }, [debouncedTableSearch])

  useEffect(() => {
    refetch()
    getTableData(
      data,
      loading,
      setTableData,
      setColumn,
      setTotalCount,
      setCursors,
      '/processed-ingredients/ingredient',
      'taxonomyProcessedFunctionalUnits',
      'taxonomyProcessedFunctionalUnit'
    )
  }, [data])

  useEffect(() => {
    if (tableSearch != null || !order[0].class) {
      if (!loading) {
        setProcessedIngredient({
          ...filterEnums(tableSearch, getProcessedIngredientVars, data),
        })
        anyRefetch({
          str: tableSearch || '',
          ...filterEnums(tableSearch, getProcessedIngredientVars, data),
          first: 100,
          after: null,
          before: null,
          last: null,
          order: order,
        })
      }
    }
  }, [tableSearch, order, loading])

  return (
    <div className={'packaging'}>
      <div style={{ maxWidth: '50rem' }} className={'mb-4'}>
        <p className={'mt-3 decoration-gray-600'}>
          <Breadcrumbs
            routes={[
              {
                path: '/processed-ingredients',
                title: 'Processed Ingredients Table',
              },
            ]}
          />
        </p>
        <h2 className={'mb-5 mt-3 text-2xl'}>Processed Ingredients</h2>
        <div className="packaging-actions">
          <Input
            onChange={e => setDebouncedTableSearch(e.target.value)}
            iconType="search"
            placeholder="Search"
          />
          <Button
            variant="primary"
            onClick={() => navigate('/processed-ingredients/ingredient?itemId=new')}
          >
            Add Ingredient
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        tableData={tableData}
        columnDefs={columnDefs}
        totalNumber={totalCount}
        endCursor={endCursor}
        startCursor={startCursor}
        refetch={refetch}
        defaultVars={processedIngredient || getProcessedIngredientVars}
        setCursors={setCursors}
        tableSearch={tableSearch}
        setOrder={setOrder}
        order={order}
        suppressRowClickSelection
      />
    </div>
  )
}

export default ProcessedIngredients
