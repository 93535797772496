import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Icon, MenuPopover, ConfirmDialog, Dialog, List } from '@mondra/ui-components'

const versionList = [
  {
    id: 'app',
    label: 'App Name',
    value: 'Mondra Data Platform',
  },
  {
    id: 'ui',
    label: 'UI',
    value: process.env.REACT_APP_UI_VERSION,
  },
  {
    id: 'api',
    label: 'API',
    value: process.env.REACT_APP_API_VERSION,
  },
]

export function UserAvatarMenu() {
  const { logout, user } = useAuth0()
  const [open, setIsOpen] = useState<boolean>(false)

  const handleLogoutClick = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <MenuPopover
        triggerButtonClass="group focus-visible:!ring-offset-0 focus-visible:ring-offset-gray-800"
        menuItems={[
          //TODO: We need to this help link properly
          { id: 'about', onClick: handleOpen, value: 'About' },
          {
            id: 'help',
            className: 'inline-block relative min-h-[36px]',
            value: (
              <a
                className="absolute left-0 inline-block w-[240px] pl-8 text-left"
                href="https://support.mondra.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Help
              </a>
            ),
          },
          {
            id: 'logout',
            onClick: handleLogoutClick,
            value: 'Logout',
          },
        ]}
        trigger={
          <div className="flex flex-row items-center justify-start gap-x-1">
            <Avatar
              size="sm"
              name={`${user?.given_name} ${user?.family_name}`}
              bgColor="bg-blue-600"
              textColor="text-white"
            />
            <Icon type="caretDown" className="text-gray-400 dark:group-hover:text-gray-100" />
          </div>
        }
      />

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        size="md"
        onPrimaryBtnClick={handleClose}
        primaryBtnText="Ok"
        hideSecondaryBtn
      >
        <Dialog.Title>About this application</Dialog.Title>
        <List className="my-4 rounded border border-gray-300" items={versionList} />
      </ConfirmDialog>
    </>
  )
}
