import { AppDispatch } from '../../../redux/store'
import { getTransportActivity, getTransportActivityConfiguration } from './api'
import {
  transportFailure,
  transportRequest,
  transportSuccess,
  configuration,
} from '../../../redux/transportSlice'
import { showCorrelationIdError } from '../../../redux/correlationIdSlice'

export const fetchTransport = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
  try {
    dispatch(transportRequest())
    const { data: pieceData } = await getTransportActivity(pieceId)
    dispatch(transportSuccess(pieceData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    if (error.response.status == 400) {
      replace()
    }
    dispatch(transportFailure(error.message))
  }
}

export const getConfiguration = () => async (dispatch: AppDispatch) => {
  try {
    const { data: configurationData } = await getTransportActivityConfiguration()
    dispatch(configuration(configurationData))
  } catch (error: any) {
    if (error.showCorrelationId) {
      dispatch(showCorrelationIdError(error.CorrelationId))
    }
    dispatch(transportFailure(error.message))
  }
}
