import { AppDispatch } from '../store'
import { audit, auditFailure, auditRequest, fieldAudit, fieldAuditRequest } from '../auditSlice'
import http from '../../http'
import { API_URL } from '../../const'

export const getAudit = (entity: string, pieceId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(auditRequest())
    const { data: auditData } = await http.get(`${API_URL}/${entity}/audit/${pieceId}`)
    dispatch(audit(auditData))
  } catch (error: any) {
    dispatch(auditFailure(error.message))
  }
}

export const getFieldAudit =
  (entity: string, field: string, pieceId: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(fieldAuditRequest())
      const { data: auditData } = await http.get(`${API_URL}/${entity}/${field}/audit/${pieceId}`)
      dispatch(fieldAudit(auditData))
    } catch (error: any) {
      dispatch(auditFailure(error.message))
    }
  }
